import React, {useContext} from 'react'
import {useLocation} from 'react-router-dom';
import {config} from '../../config';
import {GlobalContext} from '../../GlobalContext';
import useBreakpoint from '../../utility/useBreakpoint';
import Button from '../Button/Button';

const query = {small: "(max-height: 547px)", mobile: "(max-width: 800px)"};

export default function LinkContainer({e, i, socialLinks, loading, loggedIn, onEdit, onChange, marker = false, marginLeft = "40px", textAlign = null}) {
    const location = useLocation();
    const match = useBreakpoint(query);
    const {state} = useContext(GlobalContext)
    const gap = match && match.small ? "20px" : "40px";
    const isCurrent = e.replace(" ", "").replace("&\n", "").toLowerCase() === location.pathname.toLowerCase().split("/")[1];

    const styleing = !marker ? {flex: "1", textAlign: "left", marginLeft, fontSize: match && match.mobile && state.settings.showOther ? "1.8rem" : null, transform: textAlign === "center" && loggedIn ? `translateX(10px)` : null} : {}
    return <li
        style={{marginTop: i === config.shopViews || i === 6 ? gap : "10px"}}
        className={!isCurrent ? " hamburgerMenu-li-unchecked" : "hamburgerMenu-li"}>
        {marker &&

            <div
                style={{
                    borderRadius: "50%",
                    width: "10px",
                    height: "10px",
                    margin: "auto 0",
                    backgroundColor: isCurrent ? "var(--alma-blue)" : "transparent",
                    marginRight: "10px",
                }}></div>
        }
        {!config.externalUrl.includes(e) &&
            <p style={{...styleing, whiteSpace: "pre", fontFamily: "alma", textAlign}}>{e}</p>
        }
        {config.externalUrl.includes(e) &&
            <a onClick={() => onChange()} style={{...styleing, whiteSpace: "pre", fontFamily: "alma", textAlign}} className={" instalink"} href={!loading ? socialLinks : "https://www.google.com"}>{e}</a>
        }
        {loggedIn && (
            <Button
                type="icon"
                iconName={i < config.shopViews ? "add" : "edit"}
                iconColor="black"
                style={{flex: 0, marginTop: "auto", marginBottom: "auto", marginLeft: "auto", marginRight: "10px"}}
                size="20"
                onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    onEdit(e);
                }}
            />
        )}
    </li>
}
