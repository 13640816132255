import React, { useContext } from "react";
import Button from "../Button/Button";
import LinkContainer from "../LinkContainer/LinkContainer";
import "./MenuDrawer.css";

import { Link } from "react-router-dom";
import { config } from "../../config";
import { GlobalContext } from "../../GlobalContext";
import useBreakpoint from "../../utility/useBreakpoint";
import { useHttpGet } from "../../utility/RestAPI";
const query = { small: "(max-height: 547px)" };

function MobileHamburgerMenu({ onEdit, onChange, loggedIn, settings, vis }) {
  const { refetch: httpLog } = useHttpGet("api/logInsta", { skip: true });
  const { data: socialLinks, loading } = useHttpGet("api/socialLinks", {
    onReceivedModify: (res) => {
      if (!res.insta.startsWith("https://")) {
        return "https://" + res.insta;
      }
      return res.insta;
    },
  });
  let menuItemsStyle = {};
  if (settings.menuDrawerTextPos === "leftAligned") {
    menuItemsStyle = {
      margin: "auto",
      transform: `translateX(calc(${-221 / 2}px + 50%))`,
    };
  } else if (settings.menuDrawerTextPos === "centered") {
    menuItemsStyle = { margin: "auto" };
  }
  const isSpecAlign =
    settings.menuDrawerTextPos === "leftAligned" ||
    settings.menuDrawerTextPos === "centered";
  if (!vis) {
    return null;
  }
  return (
    <ul className="menudrawer-hamburgerMenu-container" style={menuItemsStyle}>
      {config.menu.map((e, i) => {
        if (config.externalUrl.includes(e)) {
          return (
            <LinkContainer
              key={e}
              onEdit={onEdit}
              socialLinks={socialLinks}
              e={e}
              i={i}
              loading={loading}
              loggedIn={loggedIn}
              onChange={() => {
                httpLog({ from: "menu" });
                onChange();
              }}
              marginLeft={isSpecAlign ? "0px" : "40px"}
              textAlign={
                settings.menuDrawerTextPos === "centered" ? "center" : null
              }
            />
          );
        }
        if (!settings.showOther && e === "Other") {
          return null;
        }
        if (!settings.showAccessories && e === "Accessories") {
          return null;
        }
        if (!settings.showCustomMade && e === "Custom made") {
          return null;
        }
        if (!settings.showClothes && e === "Clothes") {
          return null;
        }
        return (
          <Link
            key={e}
            style={{ color: "black", textDecoration: "none" }}
            onClick={() => {
              onChange();
            }}
            to={`/${e.replace(" ", "").replace("&\n", "").toLowerCase()}`}
          >
            <LinkContainer
              key={e}
              onEdit={onEdit}
              socialLinks={socialLinks}
              e={e}
              i={i}
              loading={loading}
              loggedIn={loggedIn}
              onChange={onChange}
              marginLeft={isSpecAlign ? "0px" : "40px"}
              textAlign={
                settings.menuDrawerTextPos === "centered" ? "center" : null
              }
            />
          </Link>
        );
      })}
    </ul>
  );
}

export default function MenuDrawer({
  children,
  onEdit,
  vis,
  onClose,
  onChange,
}) {
  const { state } = useContext(GlobalContext);
  const match = useBreakpoint(query);
  let marginStyle = null;
  if (match && match.small) {
    if (state.settings.pageStyle === 1) {
      marginStyle = {
        paddingTop: "100px",
        marginTop: 0,
        height: "calc(100% - 96px)",
      };
    } else if (state.settings.pageStyle === 2) {
      marginStyle = {
        paddingTop: "0",
        marginTop: "100px",
        height: "calc(100% - 96px)",
      };
    }
  } else {
    if (state.settings.pageStyle === 1) {
      marginStyle = { paddingTop: "100px", marginTop: 0 };
    } else if (state.settings.pageStyle === 2) {
      marginStyle = { paddingTop: "0", marginTop: "100px" };
    }
  }
  return (
    <div className="menudrawer-container">
      <div className="menudrawer-menu" style={{ width: vis ? "100%" : "0" }}>
        <div
          className="menudrawer-overlay"
          style={{
            ...marginStyle,
            backgroundColor: `rgba(255,255,255,${state.settings.menuOverlayOpacity})`,
          }}
        >
          <MobileHamburgerMenu
            onChange={onChange}
            onEdit={onEdit}
            loggedIn={state.loggedIn}
            settings={state.settings}
            vis={vis}
          />
          {state.addedItems.length > 0 && (
            <Link
              to="/checkout"
              style={{
                textDecoration: "none",
                width: "100%",
                flex: "0 1 50px",
              }}
            >
              <Button
                text={`Cart ( ${state.addedItems.length} )`}
                style={{ width: "100%" }}
                onClick={() => {
                  onClose();
                }}
              />
            </Link>
          )}
        </div>
      </div>
      <div className="menudrawer-hider" style={{ opacity: vis ? 1 : 0 }}></div>
      <div className="menudrawer-child" style={{ opacity: vis ? 1 : 1 }}>
        {children}
      </div>
    </div>
  );
}
