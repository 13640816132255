import {useEffect, useState, useRef} from "react";
import axios, {CancelToken} from "axios";
import {registerGetTrigger, unRegisterGetTrigger, triggers, restAPIConfig} from "../GlobalContext";

function useHttpGet(api, params, id) {
    const mounted = useRef(true);
    const received = useRef(false);
    const pendingGet = useRef(false);
    const source = useRef(null);

    const [resp, setResp] = useState(null);
    const param = params !== undefined ? params : {};
    const stringparam = JSON.stringify(param);

    function toCall(para, id) {
        if (pendingGet.current) {
            source.current.cancel("new req");
        }
        source.current = axios.CancelToken.source();
        pendingGet.current = true;
        axios
            .get(restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/" + api, {
                params: para !== undefined ? {...para, windowWidth: window.innerWidth} : {...param.param, windowWidth: window.innerWidth},
                withCredentials: true,
                cancelToken: source.current.token,
            })
            .then((res) => {
                // console.log(memorySizeOf(res.data))
                pendingGet.current = false;
                received.current = true;
                if (!mounted.current) return;
                if ("onReceivedModify" in param) {
                    setResp(param.onReceivedModify(res.data));
                } else if (!("noOutputWhenComplete" in param)) {
                    setResp(res.data);
                }
                if ("onComplete" in param) {
                    param.onComplete(res.data, id);
                }
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    return;
                }
                if ("failedCallback" in restAPIConfig) {
                    restAPIConfig["failedCallback"]();
                }
                if ("onFailed" in param) {
                    param.onFailed(err.response ? err.response.status : null, err.response ? err.response.data : err.response);
                }
            });
    }

    useEffect(() => {
        mounted.current = true;
        if (param.reload) {
            received.current = false;
            setResp(null);
        }
        if (!param.skip) {
            toCall(param.param, id);
        }
        if ("pollInterval" in param) {
            const inter = setInterval(() => {
                toCall(param.param, id);
            }, param.pollInterval);
            return () => {
                mounted.current = false;
                clearInterval(inter);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.skip, stringparam]);
    useEffect(() => {
        mounted.current = true;
        registerGetTrigger(api, toCall);
        return () => {
            mounted.current = false;
            unRegisterGetTrigger(api, toCall);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, stringparam]);

    return {loading: !received.current, data: resp, refetch: toCall};
}
//in express server: post: req.body, get: req.query, delete: req.body
function useHttpPost(api, param, onUploadProgress) {
    function toCall(parameters, id) {
        let source = CancelToken.source();
        axios
            .post(restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/" + api, parameters, {
                onUploadProgress: onUploadProgress ? (progress) => onUploadProgress(progress, id) : null,
                withCredentials: true,
                cancelToken: source.token,
            })
            .then((res) => {
                if (param !== undefined) {
                    if ("onComplete" in param) {
                        param.onComplete(res.data, id);
                    }

                    if ("triggerGet" in param) {
                        setTimeout(
                            () => {
                                param.triggerGet.forEach((t) => {
                                    if (t in triggers)
                                        triggers[t].forEach((g) => {
                                            g();
                                        });
                                });
                            },
                            "triggerDelay" in param ? param.triggerDelay : 0
                        );
                    }
                }
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    if ("onCancel" in param) {
                        param.onCancel(
                            err.message,
                            () => {
                                setTimeout(
                                    () => {
                                        param.triggerGet.forEach((t) => {
                                            if (t in triggers)
                                                triggers[t].forEach((g) => {
                                                    g();
                                                });
                                        });
                                    },
                                    "triggerDelay" in param ? param.triggerDelay : 0
                                );
                            },
                            id
                        );
                    }
                } else if (param) {
                    if ("onFailed" in param) {
                        if (err.response) {
                            param.onFailed(err.response.status, err.response.data, id);
                        } else {
                            param.onFailed(err.name, err.message, id);
                        }
                    }
                }
            });
        if (param && "onBegin" in param) {
            param.onBegin(source, id);
        }
    }
    return toCall;
}

function useHttpDelete(api, param) {
    function toCall(parameters) {
        axios.delete(restAPIConfig.protocol + "://" + restAPIConfig["ip"] + ":" + restAPIConfig["port"] + "/" + api, {data: parameters, withCredentials: true}).then((res) => {
            if (param !== undefined) {
                if ("onComplete" in param) {
                    param.onComplete(res.data);
                }
                if ("triggerGet" in param) {
                    param.triggerGet.forEach((t) => {
                        if (t in triggers)
                            triggers[t].forEach((g) => {
                                g();
                            });
                    });
                }
            }
        });
    }
    return toCall;
}

export {useHttpGet, useHttpPost, useHttpDelete};
