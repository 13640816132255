module.exports.config = {
  navigations: ["Shop", "About", "SelectedItem"],
  menu: [
    "Clothes",
    "Accessories",
    "Custom made",
    "About",

    "Contact",
    "Instagram",
    "Shipping &\npayment",
  ],
  externalUrl: ["Instagram"],
  shopViews: 3,
  thumbnailQuality: 2,
  videoFiles: [".mp4", ".mov", ".mkv"],
  itemStatus: ["Available", "Reserved", "Sold", "Sold, customer visible"],
  mobile: { small: "(max-width: 800px)", xxxsmall: "(max-width: 313px)" },
  notificationColors: {
    success: "#0CA919",
    information: "var(--alma-pink)",
    error: "#F03040",
    warning: "#FF7300",
  },
};
