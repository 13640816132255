import React from 'react'
import "./NotFoundItem.css"

export default function NotFoundItem() {

    return (
        <div className='NotFoundItem-container'>
            <p>{"This item might not exist\nor is no longer available."}</p>
        </div>
    )
}
