import React, { useRef, useState } from "react";
import Modal from "../Modal/Modal";
import "./UploadNewItem.css";
import Input from "../Input/Input";
import ImageChooser from "./ImageChooser";
import RadioButton from "../RadioGroup/RadioButton";
import { useHttpGet } from "../../utility/RestAPI";
import { restAPIConfig } from "../../GlobalContext";
import Button from "../Button/Button";
import { config } from "../../config";

export default function UploadNewItem({
  onClose,
  onSubmit,
  itemId,
  onDelete,
  type,
  modalZ,
}) {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [info, setInfo] = useState("");
  const [itemState, setItemState] = useState(1);
  const [_id, set_id] = useState(0);
  const [images, setImages] = useState([]);
  const initialIds = useRef([]);
  const [title, setTitle] = useState("");
  const [sortNbr, setsortNbr] = useState(100);
  useHttpGet("api/item", {
    skip: !itemId,
    param: { itemId, landing: type === "landing" },
    onComplete: (data) => {
      if (type !== "landing") {
        setName(data.name);
        setPrice(data.price);
        setInfo(data.info);
        setItemState(data.status);
        setTitle(data.title);
        setsortNbr(data.sortNbr);
      }
      set_id(data._id);
      setImages(
        data.images.map((e) => {
          return {
            _id: e._id,
            width: e.width,
            height: e.height,
            src:
              restAPIConfig.protocol +
              "://" +
              restAPIConfig.ip +
              ":" +
              restAPIConfig.port +
              "/api/image/?itemId=" +
              data._id +
              "&imageId=" +
              e._id,
          };
        })
      );
      initialIds.current = data.images.map((e) => e._id);
    },
  });
  return (
    <Modal
      modalZ={modalZ}
      footer={{ ok: "Submit", cancel: "Cancel" }}
      percY="5"
      onOk={() => {
        onSubmit(
          name,
          price,
          title,
          info,
          itemState,
          sortNbr,
          _id,
          images.map((e) => {
            return {
              _id: e._id,
              width: e.width,
              height: e.height,
              file: e.file,
            };
          }),
          initialIds.current
        );
      }}
      disabledOk={
        type !== "landing" &&
        (name === "" || price === "" || images.length === 0)
      }
      title={!itemId ? "Upload new " + type : "Modify " + name}
      clicked={(type) => {
        if (type !== "outside") onClose();
      }}
    >
      <article className="uploadNewItem-container">
        {type !== "landing" && (
          <>
            <div style={{ display: "flex" }}>
              <Input label="Name" value={name} onChange={(e) => setName(e)} />
              {onDelete && (
                <Button
                  text="Delete"
                  type="link"
                  style={{ marginLeft: "auto" }}
                  onClick={onDelete}
                />
              )}
            </div>
            <Input
              style={{ marginTop: "10px" }}
              type="number"
              label="sort number"
              value={sortNbr}
              onChange={(e) => setsortNbr(e)}
            />
            <Input
              style={{ marginTop: "10px" }}
              type="number"
              label="Price"
              value={price}
              onChange={(e) => setPrice(e)}
            />
            <Input
              style={{ marginTop: "10px" }}
              label="Title"
              value={title}
              onChange={(e) => setTitle(e)}
            />
            <Input
              type="textarea"
              style={{ marginTop: "10px" }}
              label="Info"
              value={info}
              onChange={(e) => setInfo(e)}
            />
            {config.itemStatus.map((e, i) => {
              return (
                <RadioButton
                  key={e}
                  value={itemState === 1 + i}
                  text={e}
                  onChecked={() => setItemState(i + 1)}
                />
              );
            })}
          </>
        )}
        <ImageChooser images={images} setImages={setImages} />
      </article>
    </Modal>
  );
}
