import React, { useState } from "react";
import { useHttpPost } from "../../utility/RestAPI";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";

export default function LoginView({ onAuthenticated, onCancel }) {
    const [pass, setPass] = useState("");
    const [wrongPass, setWrongPass] = useState(false);
    const httpLogin = useHttpPost("api/authenticate", {
        onComplete: (data) => {
            if (data.isAuthenticated) {
                onAuthenticated();
            } else {
                setWrongPass(true);
            }
        },
    });
    return (
        <Modal
            title="Login"
            clicked={(type) => {
                if (type !== "outside") {
                    onCancel();
                }
            }}
            onOk={() => {
                httpLogin({ pass });
            }}
            footer={{ ok: "Submit", cancel: "Cancel" }}>
            <article style={{ padding: "40px 10px" }}>
                <Input
                    autoFocus
                    onReturn={() => {
                        httpLogin({ pass });
                    }}
                    label="Password"
                    type="password"
                    onChange={(e) => setPass(e)}
                    value={pass}
                />
                {wrongPass && <p style={{ color: "red", fontSize: "1.2rem" }}>Wrong password</p>}
            </article>
        </Modal>
    );
}
