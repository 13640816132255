import React, { useContext, useEffect, useRef, useState } from "react";
import "./LandingPage.css";
import { GlobalContext, restAPIConfig } from "../../GlobalContext";
import Div100vh from "react-div-100vh";
import { ReactComponent as Instagram } from "../../resources/icons/svgs/instagram.svg";
import { Link } from "react-router-dom";
import ReactResizeDetector from "react-resize-detector";
import { useHttpGet } from "../../utility/RestAPI";
import useBreakpoint from "../../utility/useBreakpoint";

const query = { small: "(max-width: 800px)" };
function LandingImages({ images, id }) {
  const match = useBreakpoint(query);
  let renderImages = images || [];
  if (match && match.small) {
    renderImages = [renderImages[renderImages.length - 1]];
  }
  return (
    <>
      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        {renderImages.map((img) => {
          return (
            <div
              key={img._id}
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                minWidth: "100px",
                width: "100%",
                backgroundImage:
                  "url(" +
                  `${restAPIConfig.protocol}://${restAPIConfig.ip}:${restAPIConfig.port}/api/image/?itemId=${id}&imageId=${img._id}&quality=80` +
                  "}",
              }}
              src={
                restAPIConfig.protocol +
                "://" +
                restAPIConfig.ip +
                ":" +
                restAPIConfig.port +
                "/api/image/?itemId=" +
                id +
                "&imageId=" +
                img._id +
                "&quality=" +
                80
              }
            />
          );
        })}
      </div>
      <p
        className="landingImages-text"
        style={{
          position: "absolute",
          zIndex: 1,
          top: "50%",
          transform: "translateY(-50%)",
          margin: "0",
          width: "100%",
          fontWeight: "bold",
          textAlign: "center",
          color: renderImages.length === 0 ? "black" : "white",
          fontFamily: "alma",
        }}
      >
        avalma
      </p>
    </>
  );
}

export default function LandingPage({ instaLink }) {
  const { state } = useContext(GlobalContext);
  const [buttonHeight, setbuttonHeight] = useState(150);
  const [buttonFontSize, setbuttonFontSize] = useState(2);
  const vidRef = useRef(null);
  const { refetch: httpLog } = useHttpGet("api/logInsta", { skip: true });
  useHttpGet("api/logView", { param: { view: "landing" } });
  const { data: landingImages, loading: loadingLandingImages } = useHttpGet(
    "api/item",
    {
      skip: state.settings.landingVideo,
      param: { landing: true },
    }
  );
  function calcBtnHeight(vidHeight) {
    const btnMargin = 10;
    if (window.innerWidth > 800) {
      setbuttonHeight(150);
      setbuttonFontSize(2);
      return;
    }
    if (window.innerWidth > 415 && window.innerWidth < 800) {
      setbuttonFontSize(1.5);
    } else {
      setbuttonFontSize(2);
    }
    const desired =
      window.innerHeight / 2 - (vidHeight * 1.9) / 2 - btnMargin * 2;
    const diffOver = window.innerWidth - desired * 2 - btnMargin * 4;
    if (diffOver < 0) {
      setbuttonHeight(Math.min(desired + diffOver / 2, 150));
    } else {
      setbuttonHeight(Math.min(Math.max(desired, 100), 150));
    }
  }
  useEffect(() => {
    const asp = 1920 / 1080;
    const targetHeight = window.innerWidth / asp;
    calcBtnHeight(targetHeight);
  });
  return (
    <Div100vh className="LandingPage-container" style={{ height: "99.99rvh" }}>
      {state.settings.landingVideo && (
        <video
          ref={vidRef}
          className="landingVideo"
          playsInline
          autoPlay
          muted
          loop
          src={
            restAPIConfig.protocol +
            "://" +
            restAPIConfig.ip +
            ":" +
            restAPIConfig.port +
            "/api/landingMovie/?windowSize=" +
            window.innerWidth
          }
        />
      )}
      {!state.settings.landingVideo && !loadingLandingImages && (
        <>
          <LandingImages images={landingImages.images} id={landingImages._id} />
        </>
      )}
      <div className="landingPage-buttonRow">
        <a
          onClick={() => {
            httpLog({ from: "landing" });
          }}
          target="_blank"
          rel="noreferrer"
          href={instaLink}
        >
          <button
            style={{ height: buttonHeight + "px", width: buttonHeight + "px" }}
          >
            <Instagram width="60%" height="60%" style={{ margin: "auto" }} />
          </button>
        </a>
        <Link to="/clothes">
          <button
            style={{
              height: buttonHeight + "px",
              width: buttonHeight + "px",
              fontSize: buttonFontSize + "rem",
            }}
          >
            Shop
          </button>
        </Link>
      </div>
      <ReactResizeDetector
        targetRef={vidRef}
        handleHeight
        handleWidth
        onResize={() => {
          if (vidRef.current) {
            calcBtnHeight(vidRef.current.offsetHeight);
          }
        }}
      />
    </Div100vh>
  );
}
