import React, {useContext} from "react";
import {GlobalContext} from "../../GlobalContext";
import "./SnackbarHandler.css";
import {config} from "../../config";

const typeMap = {
    success: {color: config.notificationColors.success, icon: "abb/check-mark-circle-1"},
    error: {color: config.notificationColors.error, icon: "abb/error-circle-1"},
    information: {color: config.notificationColors.information, icon: "abb/information-circle-1"},
    warning: {color: config.notificationColors.warning, icon: "abb/warning-circle-1"},
};
export default function SnackbarHandler() {
    const {state, dispatch} = useContext(GlobalContext);

    return (
        <>
            {state.snackbars.map((sb, i) => {
                return (
                    <div
                        onAnimationEnd={() => {
                            if (sb.initializedAnimation) {
                                dispatch({type: "removeSnackbar", id: sb.id});
                            } else {
                                sb.initializedAnimation = true;
                            }
                        }}
                        key={sb.id}
                        className="snackbar-content"
                        style={{"--snackbar-top-pos": 50 * i + 20 + "px", backgroundColor: typeMap[sb.type].color, "--persistTimeSnackbar": state.settings.snackbarTime + "s"}}>
                        <p>{sb.text}</p>
                    </div>
                );
            })}
        </>
    );
}
