import React, { useState, useRef, useEffect } from "react";
import ReactResizeDetector from "react-resize-detector";
import "./AbsoluteDropdown.css";

export default function AbsoluteDropdown({ children, style }) {
    const [open, setOpen] = useState(true);
    const container = useRef(null);
    const allContainer = useRef(null);
    const dragStart = useRef([100, 0]);
    const [dragPosition, setDragPosition] = useState([0, 0]);
    useEffect(() => {
        if (open && container.current && container.current.style.maxHeight !== container.current.scrollHeight + "px") {
            container.current.style.maxHeight = container.current.scrollHeight + "px";
        }
    });
    if (container.current) {
        container.current.style.maxHeight = open ? container.current.scrollHeight + "px" : "30px";
    }
    return (
        <div
            ref={allContainer}
            className="absdrop-container"
            onClick={() => {
                setOpen(!open); 
            }}
            // draggable={true}
            onDrag={(e) => {
                if (e.clientX === 0 || e.clientY === 0) return;
                
                setDragPosition([e.clientX - dragStart.current[0], e.clientY - dragStart.current[1]]);
            }}
            onDragStart={(e) => {
                dragStart.current = [e.clientX - dragPosition[0], e.clientY - dragPosition[1]];
            }}
            style={{
                left: (100+dragPosition[0])+"px",
                // top: `calc(${props.percY || props.mobile || 25}% + ${dragPosition[1]}px)`,
                ...style,
            }}>
            <div className="absdrop-expandable" ref={container}>
                {children}
            </div>
            {container.current && (
                <ReactResizeDetector
                    targetRef={container}
                    handleHeight
                    handleWidth
                    onResize={() => {
                        if (open) {
                            container.current.style.maxHeight = container.current.scrollHeight + "px";
                        }
                    }}
                />
            )}
        </div>
    );
}
