import React from "react";
import "./Input.css";

export default function Input({
  type = "text",
  value,
  onChange,
  label,
  style,
  rows = "5",
  onReturn,
  width,
  noMinWidth = false,
  autoFocus,
}) {
  return (
    <div style={style} className="input-container">
      {label && <div>{label}</div>}
      {(type === "textarea" || type === "textareav") && (
        <textarea
          className="input-container-input"
          style={{
            width: width || null,
            resize: type === "textareav" ? "vertical" : null,
            minWidth: noMinWidth ? 0 : null,
          }}
          rows={rows}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      )}
      {type === "text" && (
        <input
          className="input-container-input"
          style={{
            width: width || null,
            minWidth: noMinWidth ? "0" : null,
          }}
          value={value}
          onKeyUp={(event) => {
            if (event.keyCode === 13) {
              event.preventDefault();
              if (onReturn) onReturn();
            }
          }}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      )}
      {type === "password" && (
        <input
          autoFocus={autoFocus}
          onKeyUp={(event) => {
            if (event.keyCode === 13) {
              event.preventDefault();
              if (onReturn) onReturn();
            }
          }}
          type="password"
          className="input-container-input"
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      )}
      {type === "number" && (
        <input
          className="input-container-input"
          type="number"
          style={style}
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
      )}
      {type === "rgba" && (
        <>
          <input
            className="input-container-input"
            type="color"
            value={value.color}
            onChange={(e) =>
              onChange({ color: e.currentTarget.value, alpha: value.alpha })
            }
          />
          <input
            type="number"
            min="0"
            max="1.0"
            step="0.1"
            value={value.alpha}
            onChange={(e) =>
              onChange({ color: value.color, alpha: e.currentTarget.value })
            }
          />
        </>
      )}
    </div>
  );
}
