import React from "react";
import Button from "../Button/Button";
import DropFileUploader from "../DropFileUploader/DropFileUploader";
import "./UploadNewItem.css";

import useBreakpoint from "../../utility/useBreakpoint";
const query = { small: "(max-width: 1000px)" };

function LocalImage({ src, onDelete, onUp, onDown }) {
    return (
        <article className="localImage-container">
            <img alt="img" src={src} />
            <div>
                <Button type="link" style={{ marginBottom: "30px" }} onClick={onDelete} text="Delete" />
                {onUp && <Button type="icon" onClick={onUp} iconName="up-arrow" iconColor="var(--alma-blue)" hoverColor="#395a61" />}
                {onDown && <Button type="icon" onClick={onDown} iconName="down-arrow" iconColor="var(--alma-blue)" hoverColor="#395a61" />}
            </div>
        </article>
    );
}

export default function ImageChooser({images,setImages}) {
    
    const match = useBreakpoint(query);
    return (
        <div className="imageChooser-container">
            {images.map((e, i) => {
                return (
                    <LocalImage
                        key={e._id}
                        src={e.src}
                        onDelete={() => {
                            setImages(images.filter((f) => f._id !== e._id));
                        }}
                        onUp={
                            images.length > 1 && i !== 0
                                ? () => {
                                      let tmp = images[i];
                                      images[i] = images[i - 1];
                                      images[i - 1] = tmp;
                                      setImages([...images]);
                                  }
                                : null
                        }
                        onDown={
                            images.length > 1 && i !== images.length - 1
                                ? () => {
                                      let tmp = images[i];
                                      images[i] = images[i + 1];
                                      images[i + 1] = tmp;
                                      setImages([...images]);
                                  }
                                : null
                        }
                    />
                );
            })}
            <DropFileUploader
                iconOnly={match && match.small}
                onLocalFileAdded={(local, file) => {
                    setImages([...images, { src: local, _id: Date.now(), file }]);
                }}
            />
        </div>
    );
}
