import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { GlobalContext } from "../../GlobalContext";
import { useHttpGet } from "../../utility/RestAPI";
import LinkContainer from "../LinkContainer/LinkContainer";
import "./HamburgerMenu.css";

export default function HamburgerMenu({ onEdit }) {
  const { state } = useContext(GlobalContext);
  const { refetch: httpLog } = useHttpGet("api/logInsta", { skip: true });
  const { data: socialLinks, loading } = useHttpGet("api/socialLinks", {
    onReceivedModify: (res) => {
      if (!res.insta.startsWith("https://")) {
        return "https://" + res.insta;
      }
      return res.insta;
    },
  });
  return (
    <ul
      className="hamburgerMenu-container"
      style={{
        backgroundColor: `rgba(255,255,255,${state.settings.overlayOpacity})`,
      }}
    >
      <div className="hamburgermanu-trancendent"></div>
      <div
        style={{
          position: state.settings.stickyMenu ? "sticky" : "static",
          top: "20px",
        }}
      >
        {config.menu.map((e, i) => {
          if (!state.settings.showOther && e === "Other") {
            return null;
          }
          if (!state.settings.showAccessories && e === "Accessories") {
            return null;
          }
          if (!state.settings.showCustomMade && e === "Custom made") {
            return null;
          }
          if (!state.settings.showClothes && e === "Clothes") {
            return null;
          }
          if (config.externalUrl.includes(e)) {
            return (
              <LinkContainer
                key={e}
                onEdit={onEdit}
                socialLinks={socialLinks}
                e={e}
                i={i}
                loading={loading}
                loggedIn={state.loggedIn}
                onChange={() => {
                  httpLog({ from: "menu" });
                }}
                marker
              />
            );
          }
          return (
            <Link
              key={e}
              style={{ color: "black", textDecoration: "none" }}
              to={`/${e.replace(" ", "").replace("&\n", "").toLowerCase()}`}
            >
              <LinkContainer
                key={e}
                onEdit={onEdit}
                socialLinks={socialLinks}
                e={e}
                i={i}
                loading={loading}
                loggedIn={state.loggedIn}
                onChange={() => {}}
                marker
              />
            </Link>
          );
        })}
      </div>
    </ul>
  );
}
