function toStringHHMMSS(given_seconds) {
  var hours = Math.floor(given_seconds / 3600);
  
  var minutes = Math.floor((given_seconds - hours * 3600) / 60);
  var seconds = Math.floor(given_seconds - hours * 3600 - minutes * 60);
  return (
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0")
  );
}

function toStringMMSS(given_seconds) {
  var minutes = Math.floor(given_seconds / 60);
  var seconds = Math.round(given_seconds - minutes * 60);
  return (
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0") 
  );
}

function tsToString(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp * 1000);
    var y = a.getFullYear();
    var m = ("" + (a.getMonth() + 1)).padStart(2, "0");
    var d = ("" + a.getDate()).padStart(2, "0");
    var H = ("" + a.getHours()).padStart(2, "0");
    var M = ("" + a.getMinutes()).padStart(2, "0");
    var S = a.getSeconds();

    return { y, m, d, H, M, S,dateString:(y+"-"+m+"-"+d),timeString:(H+":"+M),dateTimeString:((y+"-"+m+"-"+d)+" "+(H+":"+M)) };
}

module.exports.tsToString = tsToString
module.exports.toStringMMSS = toStringMMSS
module.exports.toStringHHMMSS = toStringHHMMSS


