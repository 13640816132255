import React, {useContext} from "react";
import {GlobalContext} from "../../GlobalContext";
import {useHttpGet} from "../../utility/RestAPI";
import useBreakpoint from "../../utility/useBreakpoint";
import PageLoader from "../Spinner/PageLoader";
import "./ShipInfoView.css";

const query = {small: "(max-width: 800px)"};
export default function ShipInfoView() {
    const {data, loading} = useHttpGet("api/genInfo");
    const {state} = useContext(GlobalContext);
    const match = useBreakpoint(query)
    const mobile = match && match.small
    if (loading) return <PageLoader />;
    return (
        <div className="shipinfo-container" style={{backgroundColor: `rgba(255,255,255,${state.settings.overlayOpacity})`}}>
            {data.text.map((e) => {
                return (
                    <div key={e.id}>
                        <h1 style={{fontSize: state.settings.fontSizeShippingH + "rem"}}>{e.heading}</h1>
                        <p style={{fontSize: mobile ? state.settings.fontSizeShipMobile + "rem" : state.settings.fontSizeShippingP + "rem", lineHeight: mobile ? state.settings.lineDistanceShipViewMobile + "rem" : state.settings.lineDistanceShipView}}>{e.paragraph}</p>
                    </div>
                );
            })}
        </div>
    );
}
