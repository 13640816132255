import React, { useContext, useState } from "react";
import { GlobalContext } from "../../GlobalContext";
import { useHttpGet, useHttpPost } from "../../utility/RestAPI";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";
import RadioButton from "../RadioGroup/RadioButton";
import PageLoader from "../Spinner/PageLoader";
import Spinner from "../Spinner/Spinner";
import "./SettingsDialog.css";

export default function OfflinePageDialog({
  onCancel,
  onLandingImagesModify,
  onVouchersOpen,
}) {
  const [settings, setSettings] = useState({});
  const { dispatch } = useContext(GlobalContext);
  const { loading } = useHttpGet("api/settings", {
    onComplete: (data) => {
      setSettings({ ...data });
    },
  });
  const httpPost = useHttpPost("api/settings", {
    onComplete: () => {
      dispatch({ type: "settings", ...settings });
    },
  });

  return (
    <Modal
      percY={5}
      title="Settings"
      clicked={(type) => {
        if (type !== "outside") {
          onCancel();
        }
      }}
      footer={{ ok: "Submit", cancel: "Cancel" }}
      onOk={() => {
        httpPost({ ...settings });
      }}
      width="fit-content"
    >
      <article style={{ padding: "10px 40px" }} className="settings-container">
        {(loading || Object.keys(settings).length === 0) && <PageLoader />}
        {!loading && Object.keys(settings).length !== 0 && (
          <>
            <h1>Vouchers</h1>
            <h3>Enable vouchers</h3>
            <RadioButton
              text="Enabled"
              value={settings.vouchersEnabled}
              onChecked={() =>
                setSettings({ ...settings, vouchersEnabled: true })
              }
            />
            <RadioButton
              text="Disabled"
              value={!settings.vouchersEnabled}
              onChecked={() =>
                setSettings({ ...settings, vouchersEnabled: false })
              }
            />
            <Button
              text="All Vouchers"
              onClick={() => {
                onVouchersOpen();
              }}
            />
            <h1>Site status</h1>
            <RadioButton
              text="Offline"
              onChecked={() => setSettings({ ...settings, pageOffline: true })}
              value={settings.pageOffline}
            />
            <RadioButton
              text="Online"
              onChecked={() => setSettings({ ...settings, pageOffline: false })}
              value={!settings.pageOffline}
            />
            <Input
              label="Text to be shown"
              onChange={(e) => setSettings({ ...settings, offlineText: e })}
              value={settings.offlineText}
              type="textarea"
              rows="3"
            />
            <h1>Landing page</h1>

            <RadioButton
              text="Video"
              onChecked={() => setSettings({ ...settings, landingVideo: true })}
              value={settings.landingVideo}
            />
            <RadioButton
              text="Images"
              onChecked={() =>
                setSettings({ ...settings, landingVideo: false })
              }
              value={!settings.landingVideo}
            />
            {!settings.landingVideo && (
              <>
                <Button
                  text="Modify images"
                  onClick={() => {
                    onLandingImagesModify();
                  }}
                />
              </>
            )}
            <h1>Page style</h1>
            <RadioButton
              text="standard"
              onChecked={() => setSettings({ ...settings, pageStyle: 1 })}
              value={settings.pageStyle === 1}
            />
            <RadioButton
              text="header-fade"
              onChecked={() => setSettings({ ...settings, pageStyle: 2 })}
              value={settings.pageStyle === 2}
            />
            <h1>Background</h1>
            <Input
              onChange={(e) => setSettings({ ...settings, overlayOpacity: e })}
              type="number"
              label="Background opacity"
              value={settings.overlayOpacity}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, menuOverlayOpacity: e })
              }
              type="number"
              label="Menu background opacity"
              value={settings.menuOverlayOpacity}
            />

            <h1>Loading indicator</h1>
            <div
              style={{
                width: "50px",
                height: "50px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Spinner />
            </div>
            <Input
              onChange={(e) =>
                setSettings({ ...settings, loadingIndicatorAnimSpeed: e })
              }
              type="number"
              label="Loading indicator animation speed"
              value={settings.loadingIndicatorAnimSpeed}
            />
            <h3>Show loading indicator in shop view</h3>
            <RadioButton
              text="Show"
              onChecked={() =>
                setSettings({ ...settings, showLoadIndicatorShopView: true })
              }
              value={settings.showLoadIndicatorShopView}
            />
            <RadioButton
              text="Hide"
              onChecked={() =>
                setSettings({ ...settings, showLoadIndicatorShopView: false })
              }
              value={!settings.showLoadIndicatorShopView}
            />
            <h1>Header</h1>
            <RadioButton
              text="Page centered"
              onChecked={() =>
                setSettings({ ...settings, headerCenterPage: true })
              }
              value={settings.headerCenterPage}
            />
            <RadioButton
              text="Content centered"
              onChecked={() =>
                setSettings({ ...settings, headerCenterPage: false })
              }
              value={!settings.headerCenterPage}
            />
            <h1>Shop view</h1>
            <Input
              onChange={(e) => setSettings({ ...settings, rowGap: e })}
              type="number"
              label="row gap"
              value={settings.rowGap}
            />
            <Input
              onChange={(e) => setSettings({ ...settings, columnGap: e })}
              type="number"
              label="column gap"
              value={settings.columnGap}
            />
            <Input
              onChange={(e) => setSettings({ ...settings, minShopImgWidth: e })}
              type="number"
              label="Min image width"
              value={settings.minShopImgWidth}
            />
            <Input
              onChange={(e) => setSettings({ ...settings, hoverBrightness: e })}
              type="number"
              label="hover brightness in %"
              value={settings.hoverBrightness}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, shopViewImgQuality: e })
              }
              type="number"
              label="image quality, max 100"
              value={settings.shopViewImgQuality}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, shopViewPriceBackground: e })
              }
              type="rgba"
              label="name background color & alpha"
              value={
                settings.shopViewPriceBackground || {
                  color: "#ffffff",
                  alpha: "1.0",
                }
              }
            />
            <h3>Type of menu on desktop</h3>
            <RadioButton
              text="static"
              onChecked={() => setSettings({ ...settings, stickyMenu: false })}
              value={!settings.stickyMenu}
            />
            <RadioButton
              text="sticky"
              onChecked={() => setSettings({ ...settings, stickyMenu: true })}
              value={settings.stickyMenu}
            />
            <h3>Menu on Mobile</h3>
            <RadioButton
              text="Left"
              onChecked={() =>
                setSettings({ ...settings, menuDrawerTextPos: "left" })
              }
              value={settings.menuDrawerTextPos === "left"}
            />
            <RadioButton
              text="Left aligned"
              onChecked={() =>
                setSettings({ ...settings, menuDrawerTextPos: "leftAligned" })
              }
              value={settings.menuDrawerTextPos === "leftAligned"}
            />
            <RadioButton
              text="Centered"
              onChecked={() =>
                setSettings({ ...settings, menuDrawerTextPos: "centered" })
              }
              value={settings.menuDrawerTextPos === "centered"}
            />
            <h3>Show Clothes menu</h3>
            <RadioButton
              text="Show"
              onChecked={() => setSettings({ ...settings, showClothes: true })}
              value={settings.showClothes}
            />
            <RadioButton
              text="Hide"
              onChecked={() => setSettings({ ...settings, showClothes: false })}
              value={!settings.showClothes}
            />
            <h3>Show Accessories menu</h3>
            <RadioButton
              text="Show"
              onChecked={() =>
                setSettings({ ...settings, showAccessories: true })
              }
              value={settings.showAccessories}
            />
            <RadioButton
              text="Hide"
              onChecked={() =>
                setSettings({ ...settings, showAccessories: false })
              }
              value={!settings.showAccessories}
            />
            <h3>Show Custom made menu</h3>
            <RadioButton
              text="Show"
              onChecked={() =>
                setSettings({ ...settings, showCustomMade: true })
              }
              value={settings.showCustomMade}
            />
            <RadioButton
              text="Hide"
              onChecked={() =>
                setSettings({ ...settings, showCustomMade: false })
              }
              value={!settings.showCustomMade}
            />
            <h1>About view</h1>
            <Input
              onChange={(e) =>
                setSettings({ ...settings, aboutMobilePadding: e })
              }
              type="number"
              label="padding when mobile"
              value={settings.aboutMobilePadding}
            />
            <Input
              onChange={(e) => setSettings({ ...settings, aboutImgQuality: e })}
              type="number"
              label="image quality, max 100"
              value={settings.aboutImgQuality}
            />
            <h1>Product view</h1>
            <Input
              onChange={(e) =>
                setSettings({ ...settings, selectedImgViewImgQuality: e })
              }
              type="number"
              label="image quality, max 100"
              value={settings.selectedImgViewImgQuality}
            />
            <h3>Carousel indicators</h3>
            <RadioButton
              text="Show"
              onChecked={() =>
                setSettings({ ...settings, showCarouselDots: true })
              }
              value={settings.showCarouselDots}
            />
            <RadioButton
              text="Hide"
              onChecked={() =>
                setSettings({ ...settings, showCarouselDots: false })
              }
              value={!settings.showCarouselDots}
            />
            <h3>Carousel indicator background</h3>
            <RadioButton
              text="Show"
              onChecked={() =>
                setSettings({ ...settings, carouselDotsBack: true })
              }
              value={settings.carouselDotsBack}
            />
            <RadioButton
              text="Hide"
              onChecked={() =>
                setSettings({ ...settings, carouselDotsBack: false })
              }
              value={!settings.carouselDotsBack}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, carouselDotsTime: e })
              }
              type="number"
              label="Visibility time"
              value={settings.carouselDotsTime}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, carouselDotsFadeTime: e })
              }
              type="number"
              label="Fade time"
              value={settings.carouselDotsFadeTime}
            />
            <h1>Image settings</h1>
            <h3>Image resolution amplification for mobile</h3>
            <Input
              onChange={(e) =>
                setSettings({ ...settings, mobileResAmplifyShopView: e })
              }
              type="number"
              label="Shop view"
              value={settings.mobileResAmplifyShopView}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, mobileResAmplifyProdView: e })
              }
              type="number"
              label="Product view"
              value={settings.mobileResAmplifyProdView}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, mobileResAmplifyAboutView: e })
              }
              type="number"
              label="About view"
              value={settings.mobileResAmplifyAboutView}
            />
            <h3>Image resolution amplification for desktop</h3>
            <Input
              onChange={(e) =>
                setSettings({ ...settings, resAmplifyShopView: e })
              }
              type="number"
              label="Shop view"
              value={settings.resAmplifyShopView}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, resAmplifyProdView: e })
              }
              type="number"
              label="Product view"
              value={settings.resAmplifyProdView}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, resAmplifyAboutView: e })
              }
              type="number"
              label="About view"
              value={settings.resAmplifyAboutView}
            />
            <h3>Image format</h3>
            <RadioButton
              text="webp"
              onChecked={() =>
                setSettings({ ...settings, imageFormat: "webp" })
              }
              value={settings.imageFormat === "webp"}
            />
            <RadioButton
              text="jpg"
              onChecked={() => setSettings({ ...settings, imageFormat: "jpg" })}
              value={settings.imageFormat === "jpg"}
            />
            <RadioButton
              text="png"
              onChecked={() => setSettings({ ...settings, imageFormat: "png" })}
              value={settings.imageFormat === "png"}
            />

            <h1>Font sizes</h1>
            <Input
              onChange={(e) => setSettings({ ...settings, fontSizeAbout: e })}
              type="number"
              label="About text"
              value={settings.fontSizeAbout}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeAboutMobile: e })
              }
              type="number"
              label="About text mobile"
              value={settings.fontSizeAboutMobile}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeBreadCrumb: e })
              }
              type="number"
              label="Product page breadcrumb"
              value={settings.fontSizeBreadCrumb}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeSelImageTitleMobile: e })
              }
              type="number"
              label="Product page title (mobile)"
              value={settings.fontSizeSelImageTitleMobile}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeSelImageTitle: e })
              }
              type="number"
              label="Product page title (Desktop)"
              value={settings.fontSizeSelImageTitle}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeSelImageInfoMobile: e })
              }
              type="number"
              label="Product page info (mobile)"
              value={settings.fontSizeSelImageInfoMobile}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeSelImageInfo: e })
              }
              type="number"
              label="Product page info (Desktop)"
              value={settings.fontSizeSelImageInfo}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeShopViewPrice: e })
              }
              type="number"
              label="Shop view price (Desktop)"
              value={settings.fontSizeShopViewPrice}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeShopViewPriceMobile: e })
              }
              type="number"
              label="Shop view price (Mobile)"
              value={settings.fontSizeShopViewPriceMobile}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeShopViewName: e })
              }
              type="number"
              label="Shop view name (Desktop)"
              value={settings.fontSizeShopViewName}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeShopViewNameMobile: e })
              }
              type="number"
              label="Shop view name (Mobile)"
              value={settings.fontSizeShopViewNameMobile}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeShippingH: e })
              }
              type="number"
              label="Shipping view header"
              value={settings.fontSizeShippingH}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeShippingP: e })
              }
              type="number"
              label="Shipping view paragraph (Desktop)"
              value={settings.fontSizeShippingP}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, fontSizeShipMobile: e })
              }
              type="number"
              label="Shipping view paragraph (Mobile)"
              value={settings.fontSizeShipMobile}
            />
            <h1>Line distances</h1>
            <Input
              onChange={(e) =>
                setSettings({ ...settings, lineDistanceProdView: e })
              }
              type="number"
              label="Product view (Desktop)"
              value={settings.lineDistanceProdView}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, lineDistanceProdViewMobile: e })
              }
              type="number"
              label="Product view (Mobile)"
              value={settings.lineDistanceProdViewMobile}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, lineDistanceShipView: e })
              }
              type="number"
              label="Ship view (Desktop)"
              value={settings.lineDistanceShipView}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, lineDistanceShipViewMobile: e })
              }
              type="number"
              label="Ship view (Mobile)"
              value={settings.lineDistanceShipViewMobile}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, lineDistanceAboutView: e })
              }
              type="number"
              label="About view (Desktop)"
              value={settings.lineDistanceAboutView}
            />
            <Input
              onChange={(e) =>
                setSettings({ ...settings, lineDistanceAboutViewMobile: e })
              }
              type="number"
              label="About view (Mobile)"
              value={settings.lineDistanceAboutViewMobile}
            />
            <h1>Notifications</h1>
            <Input
              onChange={(e) => setSettings({ ...settings, snackbarTime: e })}
              type="number"
              label="Visibility time"
              value={settings.snackbarTime}
            />
          </>
        )}
      </article>
    </Modal>
  );
}
