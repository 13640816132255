import React from "react";
import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";
import "./Spinner.css";

export default function Spinner({ style,size="10",dotSize="10" }) {
    const { state } = useContext(GlobalContext);
    return (
        <div className="spinner-container" style={{ ...style, "--animDurationLoadIndicator": state.settings.loadingIndicatorAnimSpeed+"s","--loadIndicatorSize":size+"px","--loadIndicatorDotSize":dotSize+"px" }}>
            <div className="spinner-head" style={{transformOrigin:`${size/2}px ${size/2}px`}}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
