import React, { useState } from "react";
import { useHttpPost } from "../../utility/RestAPI";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";

export default function OneLinerEdit({ instaLink, onCancel }) {
    const [link, setLink] = useState(instaLink);
    const httpPost = useHttpPost("api/socialLinks", { triggerGet: ["api/socialLinks"] });
    return (
        <Modal
            title="Change Instagram link"
            clicked={(type) => {
                if (type !== "outside") onCancel();
            }}
            onOk={() => {
                httpPost({ insta: link });
                onCancel();
            }}
            footer={{ ok: "Submit", cancel: "Cancel" }}>
            <article style={{ padding: "10px 40px" }}>
                <Input value={link} onChange={(e) => setLink(e)} label="Link" type="text" />
            </article>
        </Modal>
    );
}
