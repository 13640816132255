import React from "react";
import Button from "../Button/Button";
import "./UploadProgressBar.css";

function ProgressBar({ text = "123/123", value = 0 }) {
    return (
        <div className="progressBar-container">
            <p>{text}</p>
            <div className="progressBar-bar">
                <div style={{ width: value + "%" }}></div>
            </div>
        </div>
    );
}

export default function UploadProgressBar({ uploaded, total, name, onCancel, borderBottom }) {
    const upValue = total === 0 ? 100 : Math.round((uploaded / total) * 100);
    const text = Math.round(uploaded) + "/" + total;
    return (
        <article className="uploadprogress-container" style={{ borderBottom: borderBottom ? "solid 1px lightgray" : null }}>
            <div style={{ width: "100%" }}>
                <p style={{ whiteSpace: "nowrap", margin: "auto 0" }}>{name + ":"}</p>
                <div className="uploadprogress-loadingIndicator">
                    <ProgressBar text={text} value={upValue} />
                </div>
            </div>
            <Button
                type="icon"
                size="20px"
                iconName="cross"
                style={{ margin: "auto 0" }}
                onClick={(e) => {
                    e.stopPropagation();
                    onCancel();
                }}
            />
        </article>
    );
}
