import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { GlobalContext } from "../../GlobalContext";
import Button from "../Button/Button";
import Spinner from "../Spinner/Spinner";

import "./ShopItem.css";
function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export default function ShopItem({
  price,
  src,
  onEdit,
  imageStatus,
  id,
  type,
  status,
  reservedBy,
  isAdmin,
  mobile,
  width,
  height,
  name,
  orderNbr,
}) {
  const [over, setOver] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const contRef = useRef();
  const [imgSize, setImgSize] = useState(null);
  const { state } = useContext(GlobalContext);
  let textColor = null;
  let backColor = null;
  if (mobile) {
    textColor = "black";
    backColor = "transparent";
  } else {
    textColor = over ? "var(--alma-pink)" : "transparent";
    const rgbval = hexToRgb(state.settings.shopViewPriceBackground.color);
    backColor =
      over && state.settings.shopViewPriceBackground
        ? `rgba(${rgbval.r},${rgbval.g},${rgbval.b},${state.settings.shopViewPriceBackground.alpha})`
        : "transparent";
  }
  const pricep = (
    <>
      <p
        style={{
          fontSize: mobile
            ? state.settings.fontSizeShopViewNameMobile + "rem"
            : state.settings.fontSizeShopViewName + "rem",
          whiteSpace: "pre-wrap",
          textAlign: "center",
        }}
      >
        {name}
      </p>
      <p
        style={{
          fontSize: mobile
            ? state.settings.fontSizeShopViewPriceMobile + "rem"
            : state.settings.fontSizeShopViewPrice + "rem",
        }}
      >
        {price + " sek"}
      </p>
    </>
  );
  useLayoutEffect(() => {
    setImgSize(contRef.current.offsetWidth);
  }, []);
  const wierdLoad = navigator.vendor.toLowerCase().includes("apple");
  let infoHeader = null;
  if (status === 2 || status === 3) {
    infoHeader = (
      <h5>
        {config.itemStatus[status - 1] + "\n" + reservedBy + "\n" + orderNbr}{" "}
      </h5>
    );
  } else if (status === 4) {
    infoHeader = <h4>{"SOLD" + (isAdmin ? "\n" + orderNbr : "")}</h4>;
  }
  const typeToName = {
    bags: "accessories",
    clothes: "clothes",
    other: "custommade",
  };
  return (
    <Link
      to={`/${typeToName[type]}/${id}`}
      className="shopitem-container-link"
      onMouseEnter={() => {
        setOver(true);
      }}
      onMouseLeave={() => {
        setOver(false);
      }}
    >
      <div className="shopitem-container" ref={contRef}>
        {imageStatus && <p>Loading</p>}
        {!imageStatus && (
          <img
            className="shopitem-img"
            src={
              (imgSize ? src + "&size=" + imgSize : null) +
              "&quality=" +
              state.settings.shopViewImgQuality +
              "&amplify=" +
              (mobile
                ? state.settings.mobileResAmplifyShopView
                : state.settings.resAmplifyShopView) +
              "&imageFormat=" +
              state.settings.imageFormat
            }
            alt="alma"
            style={{
              opacity: loaded ? 1 : 0,
              height:
                !loaded && wierdLoad ? (imgSize * height) / width + "px" : null,
              width: !loaded && wierdLoad ? imgSize + "px" : null,
              "--hoverBrightness": state.settings.hoverBrightness + "%",
            }}
            height={(imgSize * height) / width}
            width={imgSize}
            onLoad={() => {
              setLoaded(true);
            }}
          />
        )}
        {!loaded && state.settings.showLoadIndicatorShopView && (
          <div
            style={{
              position: "absolute",
              top: (imgSize * height) / width / 2 + "px",
              left: imgSize / 2 + "px",
            }}
          >
            <Spinner />
          </div>
        )}
        <div
          style={{ color: textColor, backgroundColor: backColor }}
          className={mobile ? "shopitem-info-mobile" : "shopitem-info"}
        >
          {pricep}
        </div>
        {infoHeader}
        {isAdmin && (
          <>
            <Button
              type="icon"
              hoverColor="black"
              iconColor="black"
              iconName="edit"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEdit();
              }}
            />
          </>
        )}
      </div>
    </Link>
  );
}
