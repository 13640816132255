import React, { useContext, useEffect, useState } from "react";
import Div100vh from "react-div-100vh";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import AboutView from "../AboutView/AboutView";
import Button from "../Button/Button";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import ShopView from "../ShopView/ShopView";
import LandingPage from "../LandingPage/LandingPage";
import "./Application.css";
import {
  GlobalContext,
  UploadingContext,
  UploadingDispatchContext,
} from "../../GlobalContext";
import UploadNewItem from "../UploadNewItem/UploadNewItem";
import { useHttpDelete, useHttpGet, useHttpPost } from "../../utility/RestAPI";
import { ObjectID } from "bson";
import UploadProgressBar from "../UploadProgressBar/UploadProgressBar";
import AbsoluteDropdown from "../AbsoluteDropdown/AbsoluteDropdown";
import SelectedItemView from "../SelectedItemView/SelectedItemView";
import ModifyAboutView from "../ModifyAboutView/ModifyAboutView";
import OneLinerEdit from "../OnelinerEdit/OneLinerEdit";
import CheckoutView from "../CheckoutView/CheckoutView";
import useBreakpoint from "../../utility/useBreakpoint";
import { config } from "../../config";
import MenuDrawer from "../MenuDrawer/MenuDrawer";
import ShipInfoView from "../ShipInfoView/ShipInfoView";
import ModifyShipView from "../ModifyShipView/ModifyShipView";
import Spinner from "../Spinner/Spinner";
import OfflinePageDialog from "../OfflinePageDialog/OfflinePageDialog";
import { detect } from "detect-browser";
import LoginView from "../LoginView/LoginView";
import ContactView from "../ContactView/ContactView";
import PageLoader from "../Spinner/PageLoader";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import ThankYou from "../ThankYou/ThankYou";
import VoucherView from "../VoucherView/VoucherView";

function LogoutComponent() {
  const { dispatch } = useContext(GlobalContext);
  const httpLogout = useHttpPost("api/logout", {
    onComplete: () => {
      dispatch({ type: "setLoggedIn", loggedIn: false });
    },
  });
  httpLogout();

  return <Redirect to="/" />;
}

export default function Application() {
  const { state, dispatch } = useContext(GlobalContext);
  const uploadingState = useContext(UploadingContext);
  const uploadingDispatch = useContext(UploadingDispatchContext);
  const uploadingKeys = Object.keys(uploadingState.uploading);
  const [cancelLogin, setcancelLogin] = useState(false);
  const [uploadNewVis, setUploadNewVis] = useState({ vis: false });
  const [modifyAboutVis, setModifyAboutVis] = useState({ vis: false });
  const [modifyInstaVis, setModifyInstaVis] = useState({ vis: false });
  const [modifyGenVis, setModifyGenVis] = useState({ vis: false });
  const [offlinePageVis, setOfflinePageVis] = useState(false);
  const [vouchersPageVis, setVouchersPageVis] = useState({ vis: false });
  const [showMenu, setShowMenu] = useState(false);
  const { data: instaLink } = useHttpGet("api/socialLinks", {
    onReceivedModify: (data) => {
      if (data.insta.startsWith("https://")) {
        return data.insta;
      } else {
        return "https://" + data.insta;
      }
    },
  });
  const q = useBreakpoint(config.mobile);
  const location = useLocation();
  const mobile = q && q.small;
  const smallMobile = q && q.xxxsmall;
  useHttpGet("api/checkLoggedIn", {
    onComplete: (data) => {
      if (!state.loggedIn && data.loggedIn) {
        dispatch({ type: "setLoggedIn", loggedIn: true });
      }
    },
  });

  const { data: offlineData, loading: loadingOffline } = useHttpGet(
    "api/settings",
    {
      onComplete: (data) => {
        dispatch({ type: "settings", ...data });
      },
    }
  );
  const httpUploadFileFailed = useHttpPost("api/uploadFailed", {
    onComplete: () => {},
    triggerGet: ["api/allItems"],
  });

  const httpUpload = useHttpPost(
    "api/uploadItem",
    {
      onComplete: (_, info) => {
        setTimeout(() => {
          uploadingDispatch({
            type: "removeUploadingItem",
            itemName: info.name,
          });
        }, 3000);
      },
      onBegin: (cancelToken, info) => {
        uploadingDispatch({
          type: "setCancelToken",
          cancelToken,
          itemName: info.name,
        });
      },
      onFailed: (_, __, info) => {
        uploadingDispatch({ type: "cancelUpload", itemName: info.name });
        httpUploadFileFailed({ uploadingId: info.uploadingId });
        dispatch({
          type: "addSnackbar",
          snackbarType: "error",
          text: `Failed to upload.\nClose all programs using the file and try again.`,
          id: new ObjectID(),
        });
      },
      onCancel: (_, trigger) => {
        setTimeout(() => {
          trigger();
        }, 2000);
      },
      triggerGet: ["api/allItems"],
    },
    (prog, info) => {
      uploadingDispatch({
        type: "updateUploadingItem",
        progress: prog,
        itemName: info.name,
      });
    }
  );
  const httpPrepareUpload = useHttpPost("api/prepareUploadItem", {
    onComplete: (data, info) => {
      if (data.isUploading) {
        const form = new FormData();
        form.append("uploadingId", data.uploadingId);
        info.images.forEach((e) => {
          form.append(e._id, e.file);
        });
        httpUpload(form, { name: info.name, uploadingId: data.uploadingId });
      }
    },
    triggerGet: ["api/allItems"],
  });

  const httpDeleteItem = useHttpDelete("api/item", {
    triggerGet: ["api/allItems"],
  });

  useEffect(() => {
    if (uploadingKeys.length > 0) {
      window.onbeforeunload = () => "Upload in progress";
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      window.onbeforeunload = undefined;
    };
  }, [uploadingKeys.length]);

  useEffect(() => {
    let x = document.querySelector("html");
    if (mobile) {
      x.style.setProperty("overflow", !showMenu ? "auto" : "hidden");
    }
  }, [showMenu, mobile]);

  const browser = detect();
  if (browser.name === "ie") {
    return (
      <div style={{ height: "100rvh" }}>
        <h1 style={{ margin: "auto", marginTop: "100px" }}>
          Internet Explorer is not supported. Try another browser e.g. Chrome or
          Edge
        </h1>
      </div>
    );
  }

  const onEditFunc = (type) => {
    if (type === "About") {
      setModifyAboutVis({ vis: true });
    } else if (type === "Clothes") {
      setUploadNewVis({ vis: true, type: "clothes" });
    } else if (type === "Accessories") {
      setUploadNewVis({ vis: true, type: "bags" });
    } else if (type === "Custom made") {
      setUploadNewVis({ vis: true, type: "other" });
    } else if (type === "Instagram") {
      setModifyInstaVis({ vis: true });
    } else if (type === "Shipping &\npayment") {
      setModifyGenVis({ vis: true });
    }
  };
  if (loadingOffline) {
    return (
      <div style={{ height: "100vh", display: "flex" }}>
        <Spinner style={{ margin: "auto" }} />
      </div>
    );
  }
  if (
    state.settings.pageOffline &&
    !state.loggedIn &&
    location.pathname !== "/login"
  ) {
    return (
      <Div100vh className="application-offline" style={{ height: "99.99rvh" }}>
        <h1 style={{ margin: "auto" }}>{offlineData.offlineText}</h1>
      </Div100vh>
    );
  }
  if (location.pathname === "/") {
    return <LandingPage instaLink={instaLink} />;
  }
  const subLocation = location.pathname.split("/")[1].toLowerCase();
  if (
    ![
      "custommade",
      "thankyou",
      "checkout",
      "clothes",
      "accessories",
      "about",
      "shippingpayment",
      "contact",
      "instagram",
      "login",
      "logout",
    ].includes(subLocation) ||
    (subLocation === "custommade" && !state.settings.showOther)
  ) {
    return <NotFoundPage />;
  }
  return (
    <main
      style={{
        backgroundImage: state.settings.pageStyle === 1 ? "unset" : null,
      }}
      className="application-container"
    >
      <header
        className="application-header"
        style={{
          background:
            state.settings.pageStyle === 2
              ? `linear-gradient(180deg,rgba(255,255,255,0) 0%, rgba(255,255,255,${
                  showMenu
                    ? state.settings.menuOverlayOpacity
                    : state.settings.overlayOpacity
                }) 100%)`
              : null,
        }}
      >
        {state.loggedIn && (
          <span
            style={{
              position: "absolute",
              left: "15px",
              bottom: "10px",
              display: "block",
            }}
          >
            <Button
              type="icon"
              iconName="edit"
              onClick={() => {
                setOfflinePageVis({ vis: true });
              }}
            />
          </span>
        )}

        <p
          style={{
            fontSize: smallMobile ? "4.5rem" : null,
            paddingLeft: mobile
              ? 0
              : state.settings.headerCenterPage
              ? 0
              : "180px",
          }}
        >
          {location.pathname.split("/")[1] === "about" ? "alma" : "avalma"}
        </p>
        {mobile && (
          <span
            style={{
              position: "absolute",
              right: "15px",
              bottom: "18px",
              display: "block",
            }}
          >
            <Button
              type="icon"
              iconName={"menu"}
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            />
            {state.addedItems.length > 0 && !showMenu && (
              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  right: "-10px",
                  backgroundColor: "var(--alma-pink)",
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontFamily: "sans-sarif",
                  }}
                >
                  {state.addedItems.length}
                </p>
              </div>
            )}
          </span>
        )}
        {!mobile && state.addedItems.length > 0 && (
          <div>
            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <Button
                text={
                  "Cart" +
                  (state.addedItems.length > 0
                    ? ` ( ${state.addedItems.length} )`
                    : "")
                }
                disabled={state.addedItems.length === 0}
              />
            </Link>
          </div>
        )}
      </header>
      <MenuDrawer
        vis={showMenu}
        onClose={() => {
          setShowMenu(false);
        }}
        onChange={() => {
          setShowMenu(false);
        }}
        onEdit={(type) => {
          setShowMenu(false);
          onEditFunc(type);
        }}
      >
        <div className="application-main">
          {!mobile && <HamburgerMenu onEdit={onEditFunc} />}

          <div
            className="application-mainContainer"
            style={{
              backgroundImage: state.settings.pageStyle === 1 ? null : "unset",
            }}
          >
            <Switch>
              <Route path="/about" component={AboutView} />
              <Route path="/shippingpayment" exact component={ShipInfoView} />
              <Route path="/contact" exact component={ContactView} />
              <Route path="/accessories/:itemId" component={SelectedItemView} />
              <Route path="/clothes/:itemId" component={SelectedItemView} />
              <Route path="/custommade/:itemId" component={SelectedItemView} />
              <Route path="/thankyou" component={ThankYou} />
              <Route path="/accessories" exact>
                <ShopView
                  mobile={mobile}
                  type="bags"
                  onEdit={(itemId) => {
                    setUploadNewVis({ vis: true, itemId, type: "bags" });
                  }}
                />
              </Route>
              <Route path="/clothes" exact>
                <ShopView
                  mobile={mobile}
                  type="clothes"
                  onEdit={(itemId) => {
                    setUploadNewVis({ vis: true, itemId, type: "clothes" });
                  }}
                />
              </Route>
              <Route path="/custommade" exact>
                <ShopView
                  mobile={mobile}
                  type="other"
                  onEdit={(itemId) => {
                    setUploadNewVis({ vis: true, itemId, type: "other" });
                  }}
                />
              </Route>
              <Route path="/checkout">
                <CheckoutView />
              </Route>
              <Route
                path="/instagram"
                component={() => {
                  window.location.replace(instaLink);
                  return <PageLoader />;
                }}
              />

              <Route path="/login" exact>
                <LoginView
                  onCancel={() => {
                    setcancelLogin(true);
                  }}
                  onAuthenticated={() => {
                    dispatch({ type: "setLoggedIn", loggedIn: true });
                  }}
                />
                {(state.loggedIn || cancelLogin) && <Redirect to="/" />}
              </Route>

              <Route path="/logout" exact component={LogoutComponent} />
              <Route component={NotFoundPage} />
            </Switch>
          </div>
        </div>
      </MenuDrawer>
      {uploadNewVis.vis && (
        <UploadNewItem
          modalZ={uploadNewVis.modalZ}
          type={uploadNewVis.type}
          onDelete={
            uploadNewVis.itemId
              ? () => {
                  httpDeleteItem({ itemId: uploadNewVis.itemId });
                  setUploadNewVis({ vis: false });
                }
              : null
          }
          itemId={uploadNewVis.itemId}
          onSubmit={(
            name,
            price,
            title,
            info,
            status,
            sortNbr,
            _id,
            images,
            initialIds
          ) => {
            httpPrepareUpload(
              {
                name,
                price,
                title,
                info,
                status,
                type: uploadNewVis.type,
                sortNbr,
                _id,
                images: images.map((e) => {
                  return {
                    type: ".jpg",
                    _id: e._id,
                    height: e.height,
                    width: e.width,
                  };
                }),
              },
              {
                images: images.filter(
                  (e) => !initialIds.find((i) => i === e._id)
                ),
                name,
              }
            );
            if (images.some((e) => !initialIds.find((i) => i === e._id)))
              uploadingDispatch({ type: "setUploadingItem", itemName: name });
            setUploadNewVis({ vis: false });
          }}
          onClose={() => {
            setUploadNewVis({ vis: false });
          }}
        />
      )}

      {uploadingKeys.length > 0 && (
        <AbsoluteDropdown style={{ margin: "0 auto 0 20px" }}>
          {uploadingKeys.map((e, i) => {
            return (
              <UploadProgressBar
                key={e}
                name={e}
                borderBottom={i === uploadingKeys.length - 1}
                uploaded={Math.round(
                  uploadingState.uploading[e].progress.loaded / 1024
                )}
                total={Math.round(
                  uploadingState.uploading[e].progress.total / 1024
                )}
                onCancel={() => {
                  uploadingDispatch({ type: "cancelUpload", itemName: e });
                }}
              />
            );
          })}
        </AbsoluteDropdown>
      )}
      {modifyAboutVis.vis && (
        <ModifyAboutView
          onCancel={() => {
            setModifyAboutVis({ vis: false });
          }}
        />
      )}
      {modifyInstaVis.vis && (
        <OneLinerEdit
          onCancel={() => {
            setModifyInstaVis({ vis: false });
          }}
          instaLink={instaLink}
        />
      )}
      {modifyGenVis.vis && (
        <ModifyShipView
          onCancel={() => {
            setModifyGenVis({ vis: false });
          }}
        />
      )}
      {vouchersPageVis.vis && (
        <VoucherView
          onClose={() => {
            setVouchersPageVis({ vis: false });
          }}
        />
      )}
      {offlinePageVis.vis && (
        <OfflinePageDialog
          onCancel={() => {
            setOfflinePageVis({ vis: false });
          }}
          onLandingImagesModify={() => {
            setUploadNewVis({
              vis: true,
              type: "landing",
              itemId: "landing",
              modalZ: 51,
            });
          }}
          onVouchersOpen={() => {
            setVouchersPageVis({ vis: true });
          }}
        />
      )}
    </main>
  );
}
