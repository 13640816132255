import React, { useContext } from "react";
import { GlobalContext, restAPIConfig } from "../../GlobalContext";
import { useHttpGet } from "../../utility/RestAPI";
import useBreakpoint from "../../utility/useBreakpoint";
import ShopItem from "../ShopItem/ShopItem";
import PageLoader from "../Spinner/PageLoader";
import "./ShopView.css";

const query = { small: "(max-width: 800px)" };
export default function ShopView({ onEdit, type, mobile }) {
  const { loading, data } = useHttpGet("api/allItems", { param: { type } });
  const { state } = useContext(GlobalContext);
  const match = useBreakpoint(query);
  const rowGap =
    match && !match.small ? state.settings.rowGap + "px" : 15 + "px";
  const columnGap =
    match && !match.small ? state.settings.columnGap + "px" : 10 + "px";
  if (loading) return <PageLoader />;
  return (
    <div
      className="shopView-container"
      style={{
        backgroundColor: `rgba(255,255,255,${state.settings.overlayOpacity})`,
        rowGap,
        columnGap,
        "--minShopImgWidth": state.settings.minShopImgWidth + "px",
      }}
    >
      {data.items
        .sort((a, b) => {
          return parseFloat(a.sortNbr) > parseFloat(b.sortNbr) ? 1 : -1;
        })
        .map((e) => {
          return (
            <ShopItem
              onEdit={() => {
                onEdit(e._id);
              }}
              type={type}
              mobile={mobile}
              id={e._id}
              key={e._id}
              width={e.width}
              orderNbr={e.orderNbr}
              height={e.height}
              price={e.price}
              name={e.name}
              imageStatus={e.uploading}
              reservedBy={e.reservedBy}
              status={e.status}
              isAdmin={state.loggedIn}
              src={
                restAPIConfig.protocol +
                "://" +
                restAPIConfig.ip +
                ":" +
                restAPIConfig.port +
                "/api/image/?itemId=" +
                e._id +
                "&imageId=" +
                e.imageId
              }
            />
          );
        })}
    </div>
  );
}
