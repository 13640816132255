import React, { useEffect, useState, useRef } from "react";
import "./Modal.css";

import useBreakpoint from "../../utility/useBreakpoint";
import Button from "../Button/Button";
const query = {
  small: "(max-width: 900px)",
  xsmall: "(max-width: 360px)",
  xxsmall: "(max-width: 330px)",
};
export default function Modal({
  vis,
  clicked,
  percX,
  percY,
  mobile,
  style,
  children,
  footer,
  onOther,
  disabledOk,
  onOk,
  width,
  title,
  minHeight,
  modalZ,
}) {
  const match = useBreakpoint(query);
  const [dragPosition, setDragPosition] = useState([0, 0]);
  const dragStart = useRef([0, 0]);
  useEffect(() => {
    if (vis) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    return () => (document.body.style.overflowY = "auto");
  }, [vis]);
  return (
    <div
      onClick={(e) => (clicked ? clicked("outside") : null)}
      className="modal-display"
      style={{ zIndex: modalZ }}
    >
      <div
        className="modal-content"
        style={{
          "--modalwidth": width || "50%",
          left: `calc(${match && match.small ? "2.5%" : `${percX || 25}%`} + ${
            dragPosition[0]
          }px)`,
          top: `calc(${percY || mobile || 25}% + ${dragPosition[1]}px)`,
          ...style,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <header
          className="modal-header"
          draggable={true}
          onDrag={(e) => {
            if (e.clientX === 0 || e.clientY === 0) return;
            setDragPosition([
              e.clientX - dragStart.current[0],
              e.clientY - dragStart.current[1],
            ]);
          }}
          onDragStart={(e) => {
            dragStart.current = [
              e.clientX - dragPosition[0],
              e.clientY - dragPosition[1],
            ];
          }}
        >
          <div style={{ display: "flex" }}>
            <h4>{title}</h4>
          </div>
          <Button
            type="icon"
            iconName="cross"
            size="20px"
            hoverColor="black"
            onClick={() => {
              clicked("close");
            }}
          />
          {/* <button className="modal-closeButton" onClick={(e) => (clicked ? clicked("close") : null)}> */}
          {/* x */}
          {/* </button> */}
        </header>
        <div style={{ minHeight: minHeight || null }}>{children}</div>
        {footer && (
          <footer className="modal-footer">
            {footer.cancel && (
              <Button
                type="small"
                text={footer.cancel}
                onClick={() => (clicked ? clicked("cancel") : null)}
                sizeClass="small"
              />
            )}
            {footer.other && (
              <Button
                type="small"
                text={footer.other}
                onClick={() => (onOther ? onOther() : null)}
                sizeClass="small"
              />
            )}
            {footer.ok && (
              <Button
                type="small"
                text={footer.ok}
                onClick={() => (onOk ? onOk() : null)}
                sizeClass="small"
                disabled={disabledOk}
              />
            )}
          </footer>
        )}
      </div>
    </div>
  );
}
