import "./RadioGroup.css";

export default function RadioButton({ text = "hej", value = false, onChecked }) {
    return (
        <label className="container">
            {text}
            <input
                type="radio"
                checked={value}
                onChange={(e) => {
                    if (e.currentTarget.value) onChecked();
                }}
            />
            <span className="checkmark"></span>
        </label>
    );
}
