import {BrowserRouter as Router } from "react-router-dom";
import Application from "./components/Application/Application";
import {ContextProvider, UploadingProvider} from "./GlobalContext";
import "./App.css";
import SnackbarHandler from "./components/SnackbarHandler/SnackbarHandler";
function App() {
    return (
        <Router>
            <ContextProvider>
                <UploadingProvider>
                    <SnackbarHandler />
                    <Application />
                </UploadingProvider>
            </ContextProvider>
        </Router>
    );
}

export default App;
