import React, { useContext, useRef, useState } from "react";
import { GlobalContext, restAPIConfig } from "../../GlobalContext";
import { useHttpGet } from "../../utility/RestAPI";
import "./AboutView.css";
import path from "path";
import { config } from "../../config";
import Spinner from "../Spinner/Spinner";
import ReactResizeDetector from "react-resize-detector";
import PageLoader from "../Spinner/PageLoader";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import useBreakpoint from "../../utility/useBreakpoint";

const query = { small: "(max-width: 800px)" };

export default function AboutView() {
  const { data, loading } = useHttpGet("api/about");
  const { state } = useContext(GlobalContext);
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();
  const [imgSize, setImgSize] = useState(null);
  const [imgContainerWidth, setImgContainerWidth] = useState(null);
  const match = useBreakpoint(query);
  if (loading) return <PageLoader />;

  const isVideo = config.videoFiles.includes(
    path.extname(data.image.fileName).toLowerCase()
  );

  return (
    <article
      className="aboutView-container"
      style={{
        backgroundColor: `rgba(255,255,255,${state.settings.overlayOpacity})`,
      }}
    >
      {data.image && !data.uploadInProgress && (
        <>
          {isVideo && (
            <video controls>
              <source
                src={
                  restAPIConfig.protocol +
                  "://" +
                  restAPIConfig.ip +
                  ":" +
                  restAPIConfig.port +
                  "/api/aboutImage/?image=" +
                  data.image.fileName
                }
              />
              No video available
            </video>
          )}
          {!isVideo && (
            <div
              className="aboutView-image"
              ref={ref}
              style={{
                height: imgContainerWidth + "px",
                margin:
                  match && match.small
                    ? `auto ${state.settings.aboutMobilePadding}px`
                    : null,
              }}
            >
              {!loaded && (
                <div
                  style={{
                    position: "absolute",
                    left: imgSize / 2 + "px",
                    top: imgSize / 2 + "px",
                  }}
                >
                  <Spinner style={{}} />
                </div>
              )}
              <img
                onLoad={() => {
                  setLoaded(true);
                }}
                style={{ opacity: loaded ? 1 : 0 }}
                src={
                  imgSize
                    ? restAPIConfig.protocol +
                      "://" +
                      restAPIConfig.ip +
                      ":" +
                      restAPIConfig.port +
                      "/api/aboutImage/?size=" +
                      imgSize +
                      "&quality=" +
                      state.settings.aboutImgQuality +
                      "&amplify=" +
                      (match && match.small
                        ? state.settings.mobileResAmplifyAboutView
                        : state.settings.resAmplifyAboutView) +
                      "&imageFormat=" +
                      state.settings.imageFormat
                    : null
                }
                alt="Alma"
              />
            </div>
          )}
        </>
      )}
      <article
        className="about-textPane"
        style={{
          fontSize:
            match && match.small
              ? state.settings.fontSizeAboutMobile + "rem"
              : state.settings.fontSizeAbout + "rem",
          lineHeight:
            match && match.small
              ? state.settings.lineDistanceAboutViewMobile + "rem"
              : state.settings.lineDistanceAboutView + "rem",
          margin: "auto",
          marginBottom: match && match.small ? "50px" : null,
          padding:
            match && match.small
              ? `0 ${state.settings.aboutMobilePadding}px`
              : null,
        }}
      >
        {data.text || "Text"}
        <div style={{ marginTop: "2rem" }}>
          <Link
            style={{ textDecoration: "none", margin: "2rem auto 0 auto" }}
            to={`/contact`}
          >
            <Button
              type="round"
              text="Say&#10;hello!"
              style={{ margin: "auto" }}
            />
          </Link>
        </div>
      </article>
      <ReactResizeDetector
        targetRef={ref}
        handleHeight
        handleWidth
        onResize={() => {
          if (ref.current) {
            if (!imgSize) setImgSize(ref.current.offsetWidth);
            setImgContainerWidth(ref.current.offsetWidth);
          }
        }}
      />
    </article>
  );
}
