import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ReactResizeDetector from "react-resize-detector";
import { config } from "../../config";
import { GlobalContext, restAPIConfig } from "../../GlobalContext";
import { useHttpGet } from "../../utility/RestAPI";
import useBreakpoint from "../../utility/useBreakpoint";
import Button from "../Button/Button";
import NotFoundItem from "../NotFoundItem/NotFoundItem";
import PageLoader from "../Spinner/PageLoader";
import Spinner from "../Spinner/Spinner";
import "./SelectedItemView.css";

function CarouselDots({
  imgDim,
  total,
  sel,
  fadeTime = 6,
  visTime = 3,
  showBack = false,
}) {
  const [currAnim, setCurrAnim] = useState("init");
  useEffect(() => {
    if (currAnim === "init") {
      setCurrAnim("init2");
      return;
    }
    setCurrAnim("none");
    const to = setTimeout(() => {
      setCurrAnim("dot-fade");
    }, visTime * 1000);
    return () => {
      clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sel, visTime]);
  return (
    <div
      className="carouseldots-container"
      style={{
        "--total-carousel-elements": total,
        backgroundColor: showBack ? "rgba(100,100,100,0.8)" : null,
        opacity:
          currAnim === "none"
            ? 1
            : currAnim === "init" || currAnim === "init2"
            ? 0
            : null,
        animationName: currAnim,
        top: imgDim[1] - 15 + "px",
        left: imgDim[0] / 2 + "px",
        animationDuration: fadeTime + "s",
      }}
    >
      {Array.from(new Array(total)).map((_, i) => {
        return (
          <div
            key={i}
            className={sel === i ? "carouseldots-dot-sel" : "carouseldots-dot"}
          ></div>
        );
      })}
    </div>
  );
}

function ImageCarousel({ images, itemId, onImgSize, mobile, payloadHeight }) {
  const [sel, setSel] = useState(0);
  const ref = useRef();
  const containerRef = useRef();
  const maxImgHeight = 800;
  const [loaded, setLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);
  const loadedAmount = useRef(0);
  const initialContSize = useRef(0);
  const [contSize, setContSize] = useState(null);
  const { state } = useContext(GlobalContext);
  const [touching, setTouching] = useState({ started: false, startPos: [] });
  function findValidImageAspect(sel) {
    if (images[sel].width && images[sel].height) {
      return images[sel].height / images[sel].width;
    }
    for (const im of images) {
      if (im.width && im.height) {
        return im.height / im.width;
      }
    }
  }
  function updateLayout() {
    const aspect = findValidImageAspect(sel);
    const trueWidth = ref.current.height / aspect;
    let diffWidth = ref.current.width - trueWidth;

    if (Math.abs(diffWidth) < 1) {
      diffWidth = 0;
    }
    if (ref.current && containerRef.current) {
      if (!mobile)
        onImgSize(
          containerRef.current.offsetWidth -
            containerRef.current.offsetHeight / aspect,
          ref.current.offsetHeight
        );
      else {
        let mobileLeft = 0;
        if (mobile && contSize * aspect > maxImgHeight) {
          const imgWidth = maxImgHeight / aspect;
          mobileLeft = (contSize - imgWidth) / 2;
        }
        onImgSize(null, ref.current.offsetHeight, mobileLeft);
      }
    }
    setContSize(containerRef.current.offsetWidth);
  }

  useEffect(() => {
    updateLayout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sel]);
  useLayoutEffect(() => {
    initialContSize.current = containerRef.current.offsetWidth;
    setContSize(containerRef.current.offsetWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, sel]);
  let mobileLeft = 0;
  let imgDimen = [
    0,
    containerRef.current ? containerRef.current.offsetHeight : 600,
  ];
  const aspect = findValidImageAspect(sel);
  if (ref.current) {
    imgDimen[1] = ref.current.height || imgDimen[1];
    const aspect = findValidImageAspect(sel);
    imgDimen[0] = containerRef.current.offsetHeight / aspect;
  }
  let height = Math.min(contSize * aspect, payloadHeight);
  if (mobile) {
    height = Math.min(maxImgHeight, height);
  }
  height += "px";
  if (mobile && contSize * aspect > maxImgHeight) {
    const imgWidth = maxImgHeight / aspect;
    mobileLeft = (contSize - imgWidth) / 2;
  }

  function handleSwipe(type, e) {
    if (type === "start") {
      setTouching({
        started: true,
        startPos: [e.touches[0].clientX, e.touches[0].clientY],
      });
    } else if (type === "move") {
      let x = e.touches[0].clientX;
      let y = e.touches[0].clientY;
      if (touching.started) {
        let vecX = x - touching.startPos[0];
        let vecY = y - touching.startPos[1];
        if (Math.abs(vecY) < 400) {
          if (vecX < -50) {
            let nextI = sel + 1;
            if (nextI > images.length - 1) nextI = 0;
            setSel(nextI);
            setTouching({ started: false });
          } else if (vecX > 50) {
            let nextI = sel - 1;
            if (nextI < 0) nextI = images.length - 1;
            setSel(nextI);
            setTouching({ started: false });
          }
        }
      }
    } else if (type === "end") {
      setTouching({ started: false });
    }
  }
  return (
    <div
      ref={containerRef}
      className="carousel-container"
      style={{ height }}
      onTouchStart={(e) => {
        handleSwipe("start", e);
      }}
      onTouchMove={(e) => {
        handleSwipe("move", e);
      }}
      onTouchEnd={(e) => {
        handleSwipe("end", e);
      }}
    >
      {images.map((e, i) => {
        return (
          <Fragment key={e._id}>
            <img
              onLoad={() => {
                loadedAmount.current++;
                setLoaded(loadedAmount.current);
                setLoadedImages([...loadedImages, e._id]);
                // if (loadedAmount.current === 1) {
                //     setLoaded(1)
                // }
                // if (loadedAmount.current === images.length) setLoaded(images.length);
              }}
              ref={i === sel ? ref : null}
              style={{ opacity: sel === i ? 1 : 0 }}
              src={
                contSize
                  ? restAPIConfig.protocol +
                    "://" +
                    restAPIConfig.ip +
                    ":" +
                    restAPIConfig.port +
                    "/api/image/?itemId=" +
                    itemId +
                    "&imageId=" +
                    e._id +
                    "&size=" +
                    initialContSize.current +
                    "&quality=" +
                    state.settings.selectedImgViewImgQuality +
                    "&amplify=" +
                    (mobile
                      ? state.settings.mobileResAmplifyProdView
                      : state.settings.resAmplifyProdView) +
                    "&imageFormat=" +
                    state.settings.imageFormat
                  : null
              }
              alt="img"
            />

            {!loaded && (
              <div
                style={{
                  position: "absolute",
                  left: imgDimen[0] / 2 + mobileLeft + "px",
                  top: containerRef.current
                    ? containerRef.current.offsetHeight / 2
                    : 600 / 2 + "px",
                }}
              >
                <Spinner />
              </div>
            )}
          </Fragment>
        );
      })}
      {images.length > 1 && imgDimen[0] > 0 && loadedAmount.current >= 1 && (
        <>
          {state.settings.showCarouselDots && (
            <CarouselDots
              showBack={state.settings.carouselDotsBack}
              fadeTime={state.settings.carouselDotsFadeTime}
              visTime={state.settings.carouselDotsTime}
              imgDim={imgDimen}
              total={images.length}
              sel={sel}
            />
          )}
          <Button
            type="icon"
            iconColor="#ffffff99"
            hoverColor="#ffffff"
            iconNoShadow
            size="40px"
            iconName="left-arrow"
            style={{ top: imgDimen[1] / 2 - 20 + "px", left: mobileLeft }}
            onClick={() => {
              let nextI = sel - 1;
              if (nextI < 0) nextI = images.length - 1;
              setSel(nextI);
            }}
          />
          <Button
            type="icon"
            iconColor="#ffffff99"
            size="40px"
            iconNoShadow
            hoverColor="#ffffff"
            iconName="right-arrow"
            style={{
              top: imgDimen[1] / 2 - 20 + "px",
              left: mobileLeft - 42 + imgDimen[0] + "px",
            }}
            onClick={() => {
              let nextI = sel + 1;
              if (nextI > images.length - 1) nextI = 0;
              setSel(nextI);
            }}
          />
        </>
      )}
      <ReactResizeDetector
        targetRef={containerRef}
        handleHeight
        handleWidth
        onResize={() => {
          updateLayout();
        }}
      />
    </div>
  );
}

export default function SelectedItemView({ match }) {
  const { state, dispatch } = useContext(GlobalContext);
  const [imgDim, setImgDim] = useState([0, 0]);
  const refPayload = useRef();
  const { loading, data } = useHttpGet("api/item", {
    param: { itemId: match.params.itemId },
  });
  const q = useBreakpoint(config.mobile);
  const mobile = q && q.small;
  if (loading) return <PageLoader />;
  if (!data) {
    return <NotFoundItem />;
  }
  return (
    <article
      ref={refPayload}
      className="selectedItem-container"
      style={{
        backgroundColor: `rgba(255,255,255,${state.settings.overlayOpacity})`,
      }}
    >
      <div className="selectedItem-payloadRow">
        <ImageCarousel
          images={data.images}
          itemId={match.params.itemId}
          mobile={mobile}
          payloadHeight={
            refPayload.current ? refPayload.current.offsetHeight - 20 : 800
          }
          onImgSize={(w, h, mobLeft) => {
            setImgDim([isNaN(w) ? imgDim[0] : w, h, mobLeft]);
          }}
        />
        <div
          className="selectedItem-infoCol"
          style={{
            marginLeft: !mobile
              ? (imgDim[0] > 1 ? -imgDim[0] + 20 : 20) + "px"
              : imgDim[2] + "px",
            marginRight: mobile ? imgDim[2] + "px" : null,
          }}
        >
          <h1
            style={{
              textAlign: mobile ? "center" : null,
              fontSize: mobile
                ? state.settings.fontSizeSelImageTitleMobile + "rem"
                : state.settings.fontSizeSelImageTitle + "rem",
              marginBottom: mobile ? 0 : null,
            }}
          >
            {data.title}
          </h1>
          <p
            className="selectedItem-price"
            style={{
              textAlign: mobile ? "center" : null,
              fontSize: state.settings.fontSizeShopViewPrice + "rem",
              marginTop: mobile ? "10px" : null,
            }}
          >
            {data.price + " sek"}
          </p>
          {parseInt(data.status) === 1 && (
            <Button
              text="Add to cart"
              type="pink"
              style={{
                padding: "5px 60px",
                margin: mobile ? "10px auto" : "20px 0",
              }}
              disabled={state.addedItems.find((e) => e._id === data._id)}
              onClick={() => {
                dispatch({ type: "addItem", item: data });
                dispatch({
                  type: "addSnackbar",
                  snackbarType: "information",
                  text: `Added item ${data.name} to cart.`,
                  id: Date.now(),
                });
              }}
            />
          )}
          <p
            className="selectedItem-info"
            style={{
              whiteSpace: "pre-wrap",
              fontSize: mobile
                ? state.settings.fontSizeSelImageInfoMobile + "rem"
                : state.settings.fontSizeSelImageInfo + "rem",
              lineHeight: mobile
                ? state.settings.lineDistanceProdViewMobile + "rem"
                : state.settings.lineDistanceProdView + "rem",
            }}
          >
            {data.info}
          </p>
        </div>
      </div>
    </article>
  );
}
