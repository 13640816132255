import React, { createContext, useReducer } from "react";
import { config } from "./config";
const initialState = {
  currNavigation: config.navigations[0],
  loggedIn: false,
  snackbars: [],
  addedItems: [],
  addedVouchers: [],
  settings: {},
};
const local = process.env.NODE_ENV === "development";
const restAPIConfig = {
  // eslint-disable-next-line no-restricted-globals
  ip: local ? window.location.hostname : process.env.REACT_APP_SERVER_DOMAIN,
  port: local ? process.env.REACT_APP_PORT : process.env.REACT_APP_SERVER_PORT,
  protocol: local ? "http" : "https",
};
const triggers = {};
function registerGetTrigger(api, callback) {
  if (api in triggers) {
    triggers[api].push(callback);
  } else {
    triggers[api] = [callback];
  }
}

function unRegisterGetTrigger(api, callback) {
  triggers[api] = triggers[api].filter((e) => e !== callback);
}
const initialUploadingContext = {
  uploading: {},
};
const GlobalContext = createContext(initialState);
const UploadingContext = createContext(initialUploadingContext);
const UploadingDispatchContext = createContext(initialUploadingContext);

function reducer(state, action) {
  switch (action.type) {
    case "setLoggedIn":
      return { ...state, loggedIn: action.loggedIn };
    case "setNavigation":
      state.userView.currNavigation = action.navigation;
      return { ...state };

    case "addSnackbar":
      if (state.snackbars.includes(action.id)) return state;
      // state.snackbars.push({ text: action.text, type: action.snackbarType, id: action.id });
      // return { ...state };
      return {
        ...state,
        snackbars: [
          ...state.snackbars,
          { text: action.text, type: action.snackbarType, id: action.id },
        ],
      };
    case "removeSnackbar":
      return {
        ...state,
        snackbars: state.snackbars.filter((e) => e.id !== action.id),
      };

    case "addVoucher":
      if (state.addedVouchers.find((e) => e.code === action.voucher.code)) {
        return { ...state };
      }
      return {
        ...state,
        addedVouchers: [...state.addedVouchers, action.voucher],
      };

    case "removeVoucher":
      return {
        ...state,
        addedVouchers: state.addedVouchers.filter(
          (e) => e.code !== action.voucher.code
        ),
      };

    case "addItem":
      if (state.addedItems.find((e) => e._id === action.item._id))
        return { ...state };
      return { ...state, addedItems: [...state.addedItems, action.item] };

    case "removeItem":
      return {
        ...state,
        addedItems: state.addedItems.filter((e) => e._id !== action.item._id),
      };

    case "emptyCart":
      return { ...state, addedItems: [], addedVouchers: [] };

    case "settings":
      return { ...state, settings: action };

    default:
      console.error(
        "ATTENTION! the type in action is misspelled ",
        action.type
      );
      break;
  }
}

function uploadingReducer(state, action) {
  const newState = { ...state };
  switch (action.type) {
    case "setUploadingItem":
      if (action.itemName in newState.uploading) {
        return newState;
      } else {
        newState.uploading[action.itemName] = {
          progress: { loaded: 0, total: -1 },
        };
      }
      return newState;

    case "updateUploadingItem":
      // console.log("updata ",action.progress)
      newState.uploading[action.itemName].progress = action.progress;
      return newState;

    case "removeUploadingItem":
      delete newState.uploading[action.itemName];
      return newState;

    case "setCancelToken":
      newState.uploading[action.itemName]["cancelToken"] = action.cancelToken;
      return newState;

    case "cancelUpload":
      if (!newState.uploading[action.itemName]) return newState;
      newState.uploading[action.itemName].cancelToken.cancel(
        "user canceled the upload"
      );
      delete newState.uploading[action.itemName];
      return newState;

    default:
      break;
  }
}

function setRestAPIconfig({ failedCallback }) {
  restAPIConfig["failedCallback"] = failedCallback;
}

function ContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  setRestAPIconfig({
    failedCallback: () => {
      // dispatch({ type: "setLoggedIn", loggedIn: false });
    },
  });
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
}
function UploadingProvider(props) {
  const [uploadingState, uploadingDispatch] = useReducer(
    uploadingReducer,
    initialUploadingContext
  );
  return (
    <UploadingContext.Provider value={uploadingState}>
      <UploadingDispatchContext.Provider value={uploadingDispatch}>
        {props.children}
      </UploadingDispatchContext.Provider>
    </UploadingContext.Provider>
  );
}

export {
  GlobalContext,
  ContextProvider,
  initialState,
  registerGetTrigger,
  unRegisterGetTrigger,
  triggers,
  setRestAPIconfig,
  restAPIConfig,
  UploadingProvider,
  UploadingContext,
  UploadingDispatchContext,
};
