import { ObjectID } from "bson";
import React, {  useState } from "react";
import { useHttpGet, useHttpPost } from "../../utility/RestAPI";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";

export default function ModifyShipView({ onCancel }) {
    const [almaEmail, setAlmaEmail] = useState("");
    const [text, setText] = useState([]);

    useHttpGet("api/genInfo", {
        onComplete: (data) => {
            setAlmaEmail(data.almaEmail);
            setText(data.text);
        },
    });
    const httpUpdate = useHttpPost("api/genInfo", {
        onComplete: () => {
            onCancel();
        },
        triggerGet: ["api/genInfo"],
    });
    return (
        <Modal
            percY="5"
            title="Modify shipping & payment text"
            clicked={(type) => {
                if (type !== "outside") {
                    onCancel();
                }
            }}
            footer={{ ok: "Submit", cancel: "Cancel" }}
            onOk={() => {
                httpUpdate({ almaEmail, text });
                onCancel();
            }}>
            <article style={{ padding: "40px 10px", maxHeight: "70vh", overflow: "auto" }}>
                <Input
                    label="Alma's email"
                    style={{marginBottom:"10px"}}
                    onChange={(e) => {
                        setAlmaEmail(e);
                    }}
                    value={almaEmail}
                />
                <h1>Text</h1>
                {text.map((e, i) => {
                    return (
                        <div key={e.id} style={{ borderTop:i===0? "lightgray solid 1px":null, borderBottom: "lightgray solid 1px", padding: "10px 0" }}>
                            <div style={{display:"flex"}}>
                                <Input
                                    label="Heading"
                                    value={e.heading}
                                    onChange={(v) => {
                                        const newText = JSON.parse(JSON.stringify(text));
                                        newText[i].heading = v;
                                        setText(newText);
                                    }}
                                />
                                <Button
                                    type="link"
                                    text="Delete"
                                    style={{marginLeft:"auto"}}
                                    onClick={() => {
                                        setText(text.filter((t) => t.id !== e.id));
                                    }}
                                />
                            </div>
                            <Input
                                label="Paragraph"
                                type="textarea"
                                width="99%"
                                rows="5"
                                value={e.paragraph}
                                onChange={(v) => {
                                    const newText = JSON.parse(JSON.stringify(text));
                                    newText[i].paragraph = v;
                                    setText(newText);
                                }}
                            />
                        </div>
                    );
                })}
                <Button
                    text="Add"
                    style={{marginTop:"10px"}}
                    onClick={() => {
                        setText([...text, { heading: "", paragraph: "", id: String(new ObjectID()) }]);
                    }}
                />
            </article>
        </Modal>
    );
}
