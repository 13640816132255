import React from "react";
import "./ThankYou.css";

export default function ThankYou() {
  return (
    <div className="ThankYou-container">
      <p>
        {
          "Thank you for ordering :)\nAn email will be sent to you regarding shipping and payment."
        }
      </p>
    </div>
  );
}

