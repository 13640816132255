import React, {useContext, useRef, useState} from "react";
import {UploadingDispatchContext} from "../../GlobalContext";
import {useHttpGet, useHttpPost} from "../../utility/RestAPI";
import {DropFileUploaderWithFile} from "../DropFileUploader/DropFileUploader";
import Input from "../Input/Input";
import Modal from "../Modal/Modal";

export default function ModifyAboutView({onCancel}) {
    const [header, setHeader] = useState("");
    const [text, setText] = useState("");
    const [helloText, setHelloText] = useState("");
    const [image, setImage] = useState();
    const hasNewFile = useRef(false);

    const uploadingDispatch = useContext(UploadingDispatchContext);

    const {loading} = useHttpGet("api/about", {
        onComplete: (data) => {
            setHeader(data.header || "");
            setText(data.text || "");
            setHelloText(data.helloText || "")
            setImage({name: data.image.fileName, fileName: data.image.fileName, status: {uploadInProgress: false, uploadError: false}});
        },
    });

    const httpUploadAbout = useHttpPost(
        "api/about",
        {
            onComplete: (data, info) => {
                setTimeout(() => {
                    uploadingDispatch({type: "removeUploadingItem", itemName: info.name});
                }, 3000);
            },
            onBegin: (cancelToken, info) => {
                uploadingDispatch({type: "setCancelToken", cancelToken, itemName: info.name});
            },
            onFailed: (stat, dat, info) => {
                uploadingDispatch({type: "cancelUpload", itemName: info.name});
                // httpUploadFileFailed({ uploadingId: info.uploadingId });
            },
            onCancel: (msg, trigger) => {
                setTimeout(() => {
                    trigger();
                }, 2000);
            },
            triggerGet: ["api/about"],
        },
        (prog, info) => {
            uploadingDispatch({type: "updateUploadingItem", progress: prog, itemName: info.name});
        }
    );

    const httpPrepareAbout = useHttpPost("api/prepareAbout", {
        onComplete: (res, id) => {
            if (res.isUploading) {
                const form = new FormData();
                form.append("file", id.file);
                if (id.hasNewFile) httpUploadAbout(form, id);
            }
        },
        triggerGet: ["api/about"],
    });
    return (
        <Modal
            onOk={() => {
                httpPrepareAbout({header, text, helloText, image: {fileName: image.fileName, _id: image._id}}, {...image, hasNewFile: hasNewFile.current});
                if (hasNewFile.current) uploadingDispatch({type: "setUploadingItem", itemName: image.name});
                onCancel();
            }}
            title="Change about"
            footer={{ok: "Submit", cancel: "Cancel"}}
            clicked={(type) => {
                if (type !== "outside") onCancel();
            }}>
            {loading && <p>loading</p>}
            {!loading && (
                <div style={{padding: "10px 40px"}}>
                    <Input value={header} label="Header" onChange={(e) => setHeader(e)} />
                    <Input type="textarea" value={text} label="Text" onChange={(e) => setText(e)} />
                    <Input type="textarea" value={helloText} label="Hello text" onChange={(e) => setHelloText(e)} />

                    <DropFileUploaderWithFile
                        acceptedTypes={[".png", ".jpg", ".mp4", ".mov", ".mkv"]}
                        isLegal
                        uploadingFile={image}
                        onFileChanged={(newFile) => {
                            hasNewFile.current = true;
                            if (newFile) {
                                setImage({file: newFile, _id: Date.now(), fileName: newFile.name, name: newFile.name});
                            } else {
                                setImage(null);
                            }
                        }}
                    />
                </div>
            )}
        </Modal>
    );
}
