import React, { useState } from "react";
import Modal from "../Modal/Modal";
import { tsToString } from "../../utility/Time";
import "./VoucherView.css";
import { ReactComponent as DownArrow } from "../../resources/icons/svgs/downArrow.svg";
import { useHttpGet, useHttpPost, useHttpDelete } from "../../utility/RestAPI";
import Button from "../Button/Button";
import RadioButton from "../RadioGroup/RadioButton";
import Input from "../Input/Input";
import Spinner from "../Spinner/Spinner";

function VoucherEditView({ voucher, onApply, onCancel, onDelete, takenCodes }) {
  const [type, setType] = useState(voucher.type);
  const [absValue, setAbsValue] = useState(voucher.absValue);
  const [percValue, setPercValue] = useState(voucher.percValue);
  const [usagesLeft, setUsagesLeft] = useState(voucher.usagesLeft);
  const [code, setCode] = useState(voucher.code);
  const invalidCode = takenCodes.has(code) && voucher.code !== code;
  const invalidValue =
    type === "perc" ? percValue > 1 || percValue < 0 : absValue < 0;
  const invalidUsage = usagesLeft < 0;
  return (
    <Modal
      title={"Edit " + code}
      footer={{ ok: "Apply", cancel: "Cancel" }}
      disabledOk={invalidCode || invalidValue || invalidUsage}
      onOk={() => {
        onApply({ code, type, absValue, percValue, usagesLeft });
      }}
      clicked={(type) => {
        if (type !== "outside") onCancel();
      }}
    >
      <div style={{ padding: "10px 40px" }}>
        <div style={{ display: "flex" }}>
          <div>
            <div style={{ display: "flex" }}>
              <p style={{ fontWeight: "bold", marginRight: "10px" }}>User:</p>
              <p>{voucher.user}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ fontWeight: "bold", marginRight: "10px" }}>
                Creation Date:
              </p>
              <p>{tsToString(voucher.creationDate / 1000).dateTimeString}</p>
            </div>
            <Input label="Code" value={code} onChange={(e) => setCode(e)} />
            {invalidCode && (
              <p style={{ color: "red" }}>This code is already in use</p>
            )}
          </div>
          <Button
            type="link"
            text="Delete"
            style={{ marginLeft: "auto" }}
            onClick={onDelete}
          />
        </div>
        <p
          style={{ marginTop: "20px", marginBottom: "5px", fontWeight: "bold" }}
        >
          Type
        </p>
        <RadioButton
          text="Percentage"
          value={type === "perc"}
          onChecked={() => {
            setType("perc");
          }}
        />
        <RadioButton
          text="Absolute"
          value={type === "abs"}
          onChecked={() => {
            setType("abs");
          }}
        />
        {type === "perc" && (
          <div style={{ display: "flex" }}>
            <Input
              type="number"
              value={Math.round(percValue * 100) + ""}
              onChange={(e) => {
                if (isNaN(e / 100)) setPercValue(0);
                else setPercValue(e / 100);
              }}
              label={"Percentage value"}
            />
            <p style={{ marginTop: "auto" }}>%</p>
            {(percValue < 0 || percValue > 1) && (
              <p
                style={{ marginTop: "auto", color: "red", marginLeft: "10px" }}
              >
                Not allowed value
              </p>
            )}
          </div>
        )}
        {type === "abs" && (
          <div style={{ display: "flex" }}>
            <Input
              type="number"
              value={absValue}
              onChange={(e) => setAbsValue(e)}
              label={"Absolute value"}
            />
            <p style={{ marginTop: "auto" }}>Sek</p>
            {absValue < 0 && (
              <p
                style={{ marginTop: "auto", color: "red", marginLeft: "10px" }}
              >
                Not allowed value
              </p>
            )}
          </div>
        )}
        <div style={{ display: "flex" }}>
          <Input
            type="number"
            value={usagesLeft}
            label="Usages Left"
            onChange={(e) => setUsagesLeft(e)}
          />
          {usagesLeft < 0 && (
            <p style={{ color: "red", marginTop: "auto", marginLeft: "10px" }}>
              Not allowed value
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
}

function VoucherLine({ voucher, onClick }) {
  return (
    <div className="voucherTable-line">
      <div>
        <Button
          type="icon"
          iconName="edit"
          size="20px"
          onClick={() => {
            onClick(voucher.code);
          }}
        />
      </div>
      <div>{voucher.code}</div>
      <div>
        {voucher.type === "perc"
          ? voucher.percValue * 100 + "%"
          : voucher.absValue + "sek"}
      </div>
      <div>{tsToString(voucher.creationDate / 1000).dateTimeString}</div>
      <div>{voucher.usagesLeft}</div>
      <div>{voucher.user}</div>
    </div>
  );
}

function VoucherHeaderItem({ name, onClick }) {
  return (
    <div onClick={onClick} style={{ maxHeight: "20px" }}>
      <p>{name}</p>
      <DownArrow width="1.2rem" />
    </div>
  );
}

function VoucherTable({ data, onUpdate, onDelete }) {
  const [sortMode, setSortMode] = useState(2);
  const [editViewVis, setEditViewVis] = useState({ vis: false });
  let sortedVouchers = data;
  if (sortedVouchers.length > 0) {
    switch (sortMode) {
      case 0:
        sortedVouchers = sortedVouchers.sort((a, b) =>
          a.code > b.code ? 1 : -1
        );
        break;
      case 1:
        sortedVouchers = sortedVouchers.sort((a, b) => {
          if (a.type > b.type) {
            return -1;
          } else if (a.type < b.type) {
            return 1;
          } else {
            if (a.type === "perc") {
              return a.percValue > b.percValue ? -1 : 1;
            } else {
              return a.absValue > b.absValue ? -1 : 1;
            }
          }
        });
        break;
      case 2:
        sortedVouchers = sortedVouchers.sort((a, b) =>
          a.creationDate > b.creationDate ? 1 : -1
        );
        break;
      case 3:
        sortedVouchers = sortedVouchers.sort((a, b) =>
          a.usagesLeft > b.usagesLeft ? 1 : -1
        );
        break;
      case 4:
        sortedVouchers = sortedVouchers.sort((a, b) =>
          a.user.toLowerCase() > b.user.toLowerCase() ? 1 : -1
        );
        break;
      default:
    }
  }
  return (
    <>
      <div className="voucherTable-container">
        <div className="voucherTable-line voucherTable-header">
          <div></div>
          <VoucherHeaderItem
            name="Code"
            onClick={() => {
              setSortMode(0);
            }}
          />
          <VoucherHeaderItem
            name="Value"
            onClick={() => {
              setSortMode(1);
            }}
          />
          <VoucherHeaderItem
            name="Creation"
            onClick={() => {
              setSortMode(2);
            }}
          />
          <VoucherHeaderItem
            name="Usages Left"
            onClick={() => {
              setSortMode(3);
            }}
          />
          <VoucherHeaderItem
            name="User"
            onClick={() => {
              setSortMode(4);
            }}
          />
        </div>
        {sortedVouchers.map((v) => (
          <VoucherLine
            key={v.code}
            voucher={v}
            onClick={() => {
              setEditViewVis({ vis: true, voucher: v });
            }}
          />
        ))}
      </div>
      {editViewVis.vis && (
        <VoucherEditView
          takenCodes={new Set(sortedVouchers.map((e) => e.code))}
          onApply={({ type, percValue, absValue, usagesLeft, code }) => {
            onUpdate({
              newCode: code,
              type,
              percValue,
              absValue,
              usagesLeft,
              code: editViewVis.voucher.code,
            });
            setEditViewVis({ vis: false });
          }}
          onDelete={() => {
            onDelete(editViewVis.voucher.code);
            setEditViewVis({ vis: false });
          }}
          voucher={editViewVis.voucher}
          onCancel={() => {
            setEditViewVis({ vis: false });
          }}
        />
      )}
    </>
  );
}

export default function VoucherView({ onClose }) {
  const { data, loading, refetch } = useHttpGet("api/vouchers");
  const httpDeleteVoucher = useHttpDelete("api/voucher", {
    onComplete: () => {
      refetch();
    },
  });
  const httpPostVoucher = useHttpPost("api/voucher", {
    onComplete: () => {
      refetch();
    },
  });
  return (
    <Modal
      title="All Vouchers"
      width="80vw"
      percX="10"
      percY="2"
      onOk={() => {
        httpPostVoucher({ code: null });
      }}
      modalZ={51}
      clicked={(type) => {
        if (type !== "outside") {
          onClose();
        }
      }}
      footer={{ ok: "Create", cancel: "Close" }}
    >
      <>
        {loading && <Spinner style={{ margin: "auto" }} />}
        {!loading && (
          <VoucherTable
            data={data}
            onUpdate={(vals) => httpPostVoucher(vals)}
            onDelete={(code) => httpDeleteVoucher({ code })}
          />
        )}
      </>
    </Modal>
  );
}
