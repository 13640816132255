import React from "react";

import "./DropFileUploader2.css";

import Button from "../Button/Button";

export default function DroppedFile({ fileName, status, valid, invalidExplenation, onRemove, disabled, onClick }) {
    return (
        <article className="droppedfile-container">
            <div style={{ display: "flex" }}>
                {!disabled && <Button type="icon" iconName="cross" onClick={onRemove} />}
                <div style={{ display: "flex" }}>
                    {!fileName && (
                        <>
                            <p style={{ fontStyle: "italic", margin: "auto", marginLeft: "10px" }}>No file attached</p>
                        </>
                    )}
                    {onClick && (
                        <p onClick={onClick} className="droppedfile-name-clickable">
                            {fileName}
                        </p>
                    )}
                    {!onClick && <p className="droppedfile-name">{fileName}</p>}
                </div>
            </div>
            {!valid && (
                <div style={{ display: "flex" }}>
                    {/* <Icon name="abb/error-circle-1" sizeClass="small" color="red" style={{ margin: "auto 0" }} /> */}
                    <p style={{ margin: "auto 0 auto 5px" }}>{invalidExplenation}</p>
                </div>
            )}
        </article>
    );
}
// {status && (
//     <>
//         {status.uploadError && (
//             <WithTooltipSwac style={{ margin: "auto", marginLeft: "10px" }}>
//                 {/* <Icon className="linkbutton-errorUpload" color="red" name="abb/error-circle-1" /> */}
//                 <p>{"There was en error when uploading the file"}</p>
//             </WithTooltipSwac>
//         )}
//         {status.uploadInProgress && (
//             <WithTooltipSwac style={{ margin: "auto", marginLeft: "10px" }}>
//                 {/* <Icon className="linkbutton-upload" color="black" name="abb/synchronize" /> */}
//                 <p>{"The file is uploading"}</p>
//             </WithTooltipSwac>
//         )}
//     </>
// )}
