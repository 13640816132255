import React from "react";
import Div100vh from "react-div-100vh";
import "./NotFoundPage.css";

export default function NotFoundPage() {
  return (
    <Div100vh className="NotFoundPage-container" style={{ height: "99.99rvh" }}>
      <h1>{"404\nThe page you requested does not exist."}</h1>
    </Div100vh>
  );
}
