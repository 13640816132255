import React, { useState, useRef } from "react";

import "./DropFileUploader2.css";
import DroppedFile from "./DroppedFile"
import Button from "../Button/Button";

function RegularUploader2({ acceptedTypes, onLocalFileAdded }) {
    const [over, setOver] = useState(false);
    const counter = useRef(0);
    const mouseCounter = useRef(0);
    const [mouseOver, setMouseOver] = useState(false);
    const [mouseDown, setMouseDown] = useState(false);

    let txtColor = null;
    if (mouseOver) txtColor = "blue";
    if (mouseDown) txtColor = "red";
    return (
        <div className="fileuploader-container">
            <div className="fileuploader-uploadArea">
                <div
                    className={over ? "fileuploader-dropZone-over" : "fileuploader-dropZone"}
                    onDrop={(d) => {
                        d.stopPropagation();
                        d.preventDefault();
                        const fileName = d.dataTransfer.items[0].getAsFile().name;

                        const fileType = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
                        if (acceptedTypes && !acceptedTypes.includes(fileType)) {
                            setOver(false);
                            counter.current = 0;
                            return;
                        }

                        if (onLocalFileAdded) {
                            let fr = new FileReader();
                            const fref = d.dataTransfer.items[0].getAsFile();
                            fr.onload = () => {
                                onLocalFileAdded(fr.result, fref);
                            };
                            fr.readAsDataURL(fref);
                        }

                        counter.current = 0;
                        setOver(false);
                    }}
                    onMouseOver={() => {
                        if (!mouseOver) {
                            setMouseOver(true);
                        }
                    }}
                    onMouseEnter={() => {
                        mouseCounter.current++;
                        setMouseOver(true);
                    }}
                    onMouseLeave={() => {
                        mouseCounter.current--;
                        if (mouseCounter.current <= 0) {
                            setMouseOver(false);
                            mouseCounter.current = 0;
                        }
                    }}
                    onMouseDown={(e) => {
                        if (e.button === 0) setMouseDown(true);
                    }}
                    onMouseUp={(e) => {
                        if (e.button === 0) setMouseDown(false);
                    }}
                    onDragOver={(d) => {
                        d.preventDefault();
                    }}
                    onDragEnter={(d) => {
                        d.preventDefault();
                        counter.current++;
                        setOver(true);
                    }}
                    onClick={() => {
                        document.getElementById("happe").click();
                    }}
                    onDragLeave={(d) => {
                        d.preventDefault();
                        counter.current--;
                        if (counter.current === 0) setOver(false);
                    }}>
                    <div className={over ? "fileuploader-innerDropZone-over" : "fileuploader-innerDropZone"}>
                        <div>
                            <p>Drag file to here or</p>
                            <p style={{ color: mouseOver ? txtColor : null, fontSize: mouseOver ? "1.05rem" : null, lineHeight: "1rem" }}>browse</p>
                        </div>
                    </div>
                </div>

                <input
                    id="happe"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                        const fileType = e.currentTarget.files[0].name.slice(e.currentTarget.files[0].name.lastIndexOf(".")).toLowerCase();
                        if (acceptedTypes && !acceptedTypes.includes(fileType)) {
                            return;
                        }

                        if (onLocalFileAdded) {
                            let fr = new FileReader();
                            const fref = e.currentTarget.files[0];
                            if (!fref) {
                                return;
                            }
                            fr.onload = () => {
                                onLocalFileAdded(fr.result, fref);
                            };

                            fr.readAsDataURL(fref);
                        }
                    }}
                />
            </div>
        </div>
    );
}

function SmallUploader({ acceptedTypes, onLocalFileAdded }) {
    return (
        <>
            <Button
                type="icon"
                iconName="camera"
                size="50px"
                hoverColor="lightgray"
                style={{marginTop:"10px"}}
                onClick={() => {
                    document.getElementById("happe").click();
                }}
            />
            <input
                id="happe"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                    let fr = new FileReader();
                    const fref = e.currentTarget.files[0];
                    if (!fref) {
                        return;
                    }
                    fr.onload = () => {
                        onLocalFileAdded(fr.result, fref);
                    };

                    fr.readAsDataURL(fref);
                }}
            />
        </> 
    );
}

function DropFileUploaderWithFile({ onFileChanged, uploadingFile, isLegal = true, disabled,acceptedTypes,onClick }) {
    
    const toRender = uploadingFile ? (
        
        <DroppedFile
            fileName={uploadingFile.name}
            status={uploadingFile.status}
            disabled={disabled}
            onClick={onClick}
            valid={isLegal}
            invalidExplenation={"File already exists."}
            onRemove={() => {
                onFileChanged(null);
            }}
        />

    ) : (
        <RegularUploader2
            disabled={disabled}
            acceptedTypes={acceptedTypes}
            onLocalFileAdded={(f,f2) => {
                onFileChanged(f2);
            }}
        />
    );
    return <>{toRender}</>;
}

export default function DropFileUploader(props) {
    if (props.iconOnly) {
        return <SmallUploader {...props} />;
    } else {
        return <RegularUploader2 {...props} />;
    }
}

export {DropFileUploaderWithFile}
