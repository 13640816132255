import React, { useContext, useState } from "react";
import { GlobalContext } from "../../GlobalContext";
import { useHttpGet, useHttpPost } from "../../utility/RestAPI";
import Button from "../Button/Button";
import Input from "../Input/Input";
import "./ContactView.css";

export default function ContactView() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { dispatch, state } = useContext(GlobalContext);
  useHttpGet("api/logView", { param: { view: "contactView" } });
  const httpPost = useHttpPost("api/contact", {
    onComplete: (data) => {
      dispatch({
        type: "addSnackbar",
        snackbarType: "information",
        text: `Inquire sent`,
        id: Date.now(),
      });

      setSubject("");
      setName("");
      setLastName("");
      setEmail("");
      setMessage("");
    },
  });
  return (
    <div
      className="contactView-container"
      style={{
        backgroundColor: `rgba(255,255,255,${state.settings.overlayOpacity})`,
      }}
    >
      <div className="contactView-form">
        {/* <h1>Inquiries</h1> */}
        <div style={{ display: "flex" }}>
          <Input
            width="100%"
            style={{ width: "100%", marginRight: "5%" }}
            noMinWidth
            value={name}
            onChange={(e) => setName(e)}
            label="Name"
          />
          <Input
            width="100%"
            style={{ width: "100%" }}
            noMinWidth
            value={lastName}
            onChange={(e) => setLastName(e)}
            label="Last name"
          />
        </div>
        <Input
          noMinWidth
          width="100%"
          label={
            <div style={{ display: "flex" }}>
              <p>Email</p>
              <p
                style={{
                  fontSize: "1.2rem",
                  padding: 0,
                  lineHeight: "1.2rem",
                  marginLeft: "5px",
                }}
              >
                *
              </p>
            </div>
          }
          style={{ marginTop: "20px" }}
          value={email}
          onChange={(e) => setEmail(e)}
        />
        <Input
          noMinWidth
          width="100%"
          label="Subject"
          style={{ marginTop: "20px" }}
          value={subject}
          onChange={(e) => setSubject(e)}
        />
        <Input
          noMinWidth
          width="100%"
          label={
            <div style={{ display: "flex" }}>
              <p>Message</p>
              <p
                style={{
                  fontSize: "1.2rem",
                  padding: 0,
                  lineHeight: "1.2rem",
                  marginLeft: "5px",
                }}
              >
                *
              </p>
            </div>
          }
          style={{ marginTop: "20px" }}
          type="textareav"
          rows="5"
          value={message}
          onChange={(e) => setMessage(e)}
        />
        <Button
          text="Submit"
          style={{ marginTop: "20px" }}
          disabled={email === "" || !email.includes("@") || message === ""}
          onClick={() => {
            httpPost({ name, lastName, email, subject, message });
          }}
        />
      </div>
    </div>
  );
}
