import React from "react";
import "./Button.css";

import {ReactComponent as Cross} from "../../resources/icons/svgs/cross.svg";
import {ReactComponent as DownArrow} from "../../resources/icons/svgs/downArrow.svg";
import {ReactComponent as Camera} from "../../resources/icons/svgs/camera.svg";
import {ReactComponent as Edit} from "../../resources/icons/svgs/editPencil.svg";
import {ReactComponent as AddPhoto} from "../../resources/icons/svgs/a.svg";
import {ReactComponent as Menu} from "../../resources/icons/svgs/menu.svg";

export default function Button({onClick, style, text, type, disabled, iconName = "down-arrow", iconColor = "black", size = "32px", hoverColor = "blue", iconNoShadow = false}) {
    let toRender = null;
    switch (type) {
        case "link":
            toRender = (
                <button className="button-link" style={style} onClick={onClick}>
                    {text}
                </button>
            );
            break;
        case "icon":
            toRender = (
                <button className={!iconNoShadow ? "button-icon" : "button-icon-noShadow"} style={{...style, "--iconHoverColor": hoverColor}} onClick={onClick} disabled={disabled}>
                    {iconName === "down-arrow" && <DownArrow fill={iconColor} width={size} height={size} style={{transform: "rotateZ(0deg)"}} />}
                    {iconName === "left-arrow" && <DownArrow fill={iconColor} width={size} height={size} style={{transform: "rotateZ(90deg)"}} />}
                    {iconName === "up-arrow" && <DownArrow fill={iconColor} width={size} height={size} style={{transform: "rotateZ(180deg)"}} />}
                    {iconName === "right-arrow" && <DownArrow fill={iconColor} width={size} height={size} style={{transform: "rotateZ(270deg)"}} />}
                    {iconName === "cross" && <Cross fill={iconColor} width={size} height={size} />}
                    {iconName === "camera" && <Camera fill={iconColor} width={size} height={size} />}
                    {iconName === "edit" && <Edit fill={iconColor} width={size} height={size} />}
                    {iconName === "add" && <AddPhoto fill={iconColor} width={size} height={size} />}
                    {iconName === "menu" && <Menu fill={iconColor} width={size} height={size} />}
                </button>
            );
            break;

        case "small":
            toRender = (
                <button className="button-small" disabled={disabled} style={style} onClick={onClick}>
                    {text}
                </button>
            );
            break;

        case "round":

            toRender = (
                <button className="button-round" disabled={disabled} style={style} onClick={onClick}>
                    {text}
                </button>
            );
            break;

        case "pink":
            toRender = (
                <button disabled={disabled} className="button-pink" style={style} onClick={onClick}>
                    {text}
                </button>
            );
            break;

        case "yellow":
            toRender = (
                <button disabled={disabled} className="button-yellow" style={style} onClick={onClick}>
                    {text}
                </button>
            );
            break;

        default:
            toRender = (
                <button disabled={disabled} className="button" style={style} onClick={onClick}>
                    {text}
                </button>
            );
            break;
    }

    return <>{toRender}</>;
}
