import React from "react";
import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";
import Spinner from "./Spinner";

export default function PageLoader() {
    const {state} = useContext(GlobalContext)
    return (
        <div style={{ position: "relative", width: "100%", height: "calc(100vh - 100px)", backgroundColor: `rgba(255, 255, 255, ${state.settings.overlayOpacity})` }}>
            <Spinner style={{ position: "absolute", left: "50%", top: "50%" }} dotSize="20" size="20"/>
        </div>
    );
}
