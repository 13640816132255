import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../../GlobalContext";
import { useHttpGet, useHttpPost } from "../../utility/RestAPI";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Spinner from "../Spinner/Spinner";
import "./CheckoutView.css";

export default function CheckoutView() {
  const { state, dispatch } = useContext(GlobalContext);
  const [redeemedVoucher, setRedeemedVoucher] = useState(false);
  const [voucherText, setVoucherText] = useState("");
  const {
    data: voucherData,
    loading: loadingVoucher,
    refetch: fetchVoucher,
  } = useHttpGet("api/redeemVoucher", {
    skip: true,
    onComplete: (res) => {
      switch (res.result) {
        case 1:
          dispatch({
            type: "addSnackbar",
            snackbarType: "error",
            text: `The voucher code is invalid`,
            id: Date.now(),
          });
          break;
        case 2:
          dispatch({
            type: "addSnackbar",
            snackbarType: "error",
            text: `The voucher has already been used`,
            id: Date.now(),
          });
          break;

        case 3:
          if (state.addedVouchers.find((e) => e.code === voucherText)) {
            dispatch({
              type: "addSnackbar",
              snackbarType: "information",
              text: `Voucher already added`,
              id: Date.now(),
            });
            break;
          }
          dispatch({
            type: "addSnackbar",
            snackbarType: "information",
            text: `Redeemed voucher`,
            id: Date.now(),
          });
          dispatch({
            type: "addVoucher",
            voucher: { value: res.value, type: res.type, code: voucherText },
          });
          setVoucherText("");
          break;

        default:
      }
    },
  });
  const [redirectBack, setRedirectBack] = useState(false);
  useHttpGet("api/logView", { param: { view: "checkout" } });
  const httpOrder = useHttpPost("api/orderItems", {
    triggerGet: ["api/allItems"],
    onComplete: (res) => {
      if (res.result === 1) {
        dispatch({
          type: "addSnackbar",
          snackbarType: "error",
          text: `The following voucher codes did not exists:\n${res.vouchers.map(
            (e) => e.code + "\n"
          )}`,
          id: Date.now(),
        });
        res.vouchers.forEach((e) => {
          dispatch({ type: "removeVoucher", voucher: e });
        });
        return;
      }
      if (res.result === 2) {
        dispatch({
          type: "addSnackbar",
          snackbarType: "error",
          text: `The following voucher codes did not have usages left:\n${res.vouchers.map(
            (e) => e.code + "\n"
          )}`,
          id: Date.now(),
        });
        res.vouchers.forEach((e) => {
          dispatch({ type: "removeVoucher", voucher: e });
        });
        return;
      }
      dispatch({ type: "emptyCart" });
      dispatch({
        type: "addSnackbar",
        snackbarType: "information",
        text: `Ordered all items in the cart.`,
        id: Date.now(),
      });
      setRedirectBack(true);
    },
  });
  const sum = state.addedItems
    .map((e) => parseFloat(e.price))
    .reduce((a, b) => {
      return a + b;
    }, 0);
  //vouchers
  const percVouchers = state.addedVouchers.filter((e) => e.type === "perc");
  const absVouchers = state.addedVouchers.filter((e) => e.type === "abs");
  let sumAfterVouchers = percVouchers.reduce((tot, val) => {
    return tot - tot * parseFloat(val.value);
  }, sum);
  sumAfterVouchers = absVouchers.reduce((tot, val) => {
    return tot - parseFloat(val.value);
  }, sumAfterVouchers);
  sumAfterVouchers = Math.max(sumAfterVouchers, 0);
  const [email, setEmail] = useState("");
  if (redirectBack) {
    return <Redirect to="/thankyou" />;
  }
  let redeemText = "";
  if (!loadingVoucher && redeemedVoucher) {
    switch (voucherData.result) {
      case 1:
        redeemText = "The code is invalid.";
        break;

      case 2:
        redeemText = "The voucher has already been used.";
        break;

      case 3:
        redeemText = "";
        break;

      default:
    }
  }
  return (
    <div className="checkoutview-whole" style={{}}>
      <article className="checkoutview-container">
        <div className="checkoutview-table">
          <header>
            <p>Product</p>
            <p>Price</p>
          </header>
          {state.addedItems.map((e, i) => {
            return (
              <span key={i}>
                <Button
                  iconName="cross"
                  type="icon"
                  size="15"
                  style={{
                    marginBottom: "auto",
                    marginTop: "5px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    dispatch({ type: "removeItem", item: e });
                  }}
                />
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/${e.type}/${e._id}`}
                >
                  <Button
                    style={{ fontSize: "1.3rem" }}
                    type="link"
                    text={e.name}
                  />
                </Link>

                <p>{e.price + " sek"}</p>
              </span>
            );
          })}
          {state.addedVouchers.length > 0 && (
            <>
              <header style={{ marginTop: "10px" }}>
                <p>Voucher</p>
                <p>Value</p>
              </header>
              {state.addedVouchers.map((e) => {
                const text =
                  e.type === "perc"
                    ? -Math.round(e.value * 100) + "%"
                    : -e.value + " sek";
                return (
                  <span key={e.code}>
                    <Button
                      iconName="cross"
                      type="icon"
                      size="15"
                      style={{
                        marginBottom: "auto",
                        marginTop: "5px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        dispatch({ type: "removeVoucher", voucher: e });
                      }}
                    />
                    <span>{e.code}</span>
                    <p>{text}</p>
                  </span>
                );
              })}
            </>
          )}
          {state.settings.vouchersEnabled && (
            <>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "0.8rem",
                  marginBottom: "-10px",
                  marginTop: "40px",
                }}
              >
                Promo code
              </p>
              <div
                style={{
                  marginLeft: "",
                  display: "flex",
                  transform: "scale(0.8)",
                  transformOrigin: "center left",
                }}
              >
                <div style={{ overflow: "hidden", margin: "auto 0" }}>
                  <Input
                    noMinWidth={true}
                    width="100px"
                    style={{
                      marginLeft: "",
                    }}
                    value={voucherText}
                    onReturn={() => {
                      setRedeemedVoucher(true);
                      fetchVoucher({ code: voucherText });
                    }}
                    onChange={(e) => setVoucherText(e)}
                  />
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    marginLeft: "10px",
                  }}
                >
                  <Button
                    style={{
                      padding: "2px 5px",
                      margin: "auto 0",
                    }}
                    disabled={voucherText.length === 0}
                    text="Redeem"
                    onClick={() => {
                      setRedeemedVoucher(true);
                      fetchVoucher({ code: voucherText });
                    }}
                    type="small"
                  />
                  <div style={{ margin: "auto 0", marginLeft: "20px" }}>
                    {loadingVoucher && redeemedVoucher && <Spinner />}
                    {!loadingVoucher && redeemedVoucher && <p>{redeemText}</p>}
                  </div>
                </div>
              </div>
            </>
          )}
          <footer
            style={{ marginTop: state.settings.vouchersEnabled ? "5px" : null }}
          >
            <p>Total</p>
            <p>{Math.round(sumAfterVouchers) + " sek"}</p>
          </footer>
        </div>
      </article>
      <footer className="checkoutview-form">
        <Input
          value={email}
          onChange={(e) => setEmail(e)}
          label="Your email address"
          type="text"
        />
        <div className="checkoutview-order">
          <Button
            text="Order"
            type="yellow"
            disabled={
              state.addedItems.length === 0 ||
              email === "" ||
              !email.includes("@") ||
              !email.includes(".")
            }
            onClick={() => {
              httpOrder({
                email,
                items: state.addedItems.map((e) => e._id),
                voucherCodes: state.addedVouchers.map((e) => e.code),
              });
            }}
          />
          <p className="chekoutview-shiptext">
            {
              "When you press order, an email will be sent to you. I will get back to you to arrange the shipping and payment. Please note that shipping is not included in the price.\nI only use your email for this order."
            }
          </p>
        </div>
      </footer>
    </div>
  );
}
